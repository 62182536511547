import React, { useEffect, useState } from 'react';

import { AnalyticsDocumentType } from '../../types';

import { EventType } from './types';

type Props = {
  book: AnalyticsDocumentType;
  token: string | null;
  clickCallback: (e: EventType) => void;
};

function fetchWithAuth(url: string, authToken: string) {
  const headers = new Headers();
  headers.set('Authorization', `Bearer ${authToken}`);
  return fetch(url, { headers });
}

export const BookCover = (props: Props) => {
  const { book, clickCallback, token } = props;

  const [blobLink, setBlobLink] = useState('');

  useEffect(() => {
    if (!token) return;

    fetchWithAuth(book.cover_url, token)
      .then(res => res.blob())
      .then(blob => {
        const objectUrl = URL.createObjectURL(blob);
        setBlobLink(objectUrl);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div key={book.id} className="BookCover" onClick={clickCallback}>
      {blobLink && <img alt="" src={blobLink} />}
    </div>
  );
};
