import React, { useEffect, useState } from 'react';

import './ChapterAnalytics.scss';

import { ChapterStatus, ChapterType, WindowType, UserType } from '../../types';
import { API_HOST, ATTENTION_METRIC } from '../../consts';
import { jsonParse } from '../../helpers';

import { NotRead } from './NotRead';
import { InProgress } from './InProgress';
import { Done } from './Done';

type Props = {
  setIsLoading: (value: boolean) => void;
  setInProcess: (value: boolean) => void;
  setError: (error: string) => void;
};

export const ChapterAnalytics = (props: Props): JSX.Element | null => {
  const wnd = (window as unknown) as WindowType;
  const urlParams = new URLSearchParams(window.location.search);
  // chapterId has a form {documentId}/{chapterNumber}
  // for example: 1s34jcnysru2we3iio/2/3
  // so the first string before slash is documentId
  // and others are pointer for specific chapter
  const documentAndChapterId = urlParams.get('chapterId');

  const [chapter, setChapter] = useState<ChapterType | null>(null);
  const [user, setUser] = useState<UserType | null>(null);

  useEffect(() => {
    if (!documentAndChapterId) return;

    const [documentId, ...chapterIdArr] = documentAndChapterId.split('/');

    Promise.all([
      fetch(`${API_HOST}/profile/`, {
        headers: { Authorization: `Bearer ${wnd.token}` }
      }).then(async response => {
        const results = jsonParse<UserType>(await response.text());
        setUser(results);
      }),

      fetch(`${API_HOST}/documents/${documentId}/chapters/${chapterIdArr.join('/')}/?language=${wnd.currentLang}`, {
        headers: { Authorization: `Bearer ${wnd.token}` }
      }).then(async response => {
        const results = jsonParse<ChapterType>(await response.text());
        // console.log(results);
        setChapter(results);
      })
    ])
      .then(() => {
        props.setIsLoading(false);
        props.setInProcess(false);
      })
      .catch(e => props.setError(e));
    // eslint-disable-next-line
  }, []);

  if (!chapter || !user) {
    return null;
  }

  const isNotRead = chapter.status === ChapterStatus.NOT_READ;
  const isInProgress = chapter.status === ChapterStatus.IN_PROGRESS;
  const isDone = chapter.status === ChapterStatus.DONE;

  const attentionMetric = chapter.metrics?.find(item => item.name === ATTENTION_METRIC);
  const showError = (isInProgress || isDone) && !attentionMetric?.value;

  return (
    <div className="ChapterAnalytics">
      {showError && <div className="analytics-error">{wnd.langDict.web.aggregated.chapter.failed}</div>}
      {isNotRead && <NotRead chapter={chapter} user={user} />}
      {isInProgress && <InProgress chapter={chapter} user={user} />}
      {isDone && <Done chapter={chapter} />}
    </div>
  );
};
