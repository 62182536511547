import React, { FC } from 'react';

import { MetricInfoType, MetricType } from '../../types';
import { ATTENTION_METRIC, COMPREHENSION_METRIC } from '../../consts';

import { AttentionIcon } from './AttentionIcon';
import { ComprehensionIcon } from './ComprehensionIcon';

export type IconProps = {
  width: number;
  height: number;
  metricInfo: MetricInfoType;
  metric: MetricType;
};

type Colors = {
  border: string;
  filled: string;
};

const getColor = (percents: number): Colors => {
  if (percents === 0) {
    return {
      border: '#999999',
      filled: '#999999'
    };
  }
  if (percents < 20) {
    return {
      border: '#E03434',
      filled: '#EC8585'
    };
  }
  if (percents < 40) {
    return {
      border: '#E6682C',
      filled: '#F0A480'
    };
  }
  if (percents < 60) {
    return {
      border: '#EC9B24',
      filled: '#F4C37C'
    };
  }
  if (percents < 80) {
    return {
      border: '#9BB012',
      filled: '#C3D071'
    };
  }
  return {
    border: '#49C400',
    filled: '#A4D99C'
  };
};

export const MetricIcon: FC<IconProps> = ({ width, height, metric, metricInfo }) => {
  const percent = Math.round((metricInfo.metainfo.invert ? 1 - metric.value : metric.value) * 100);
  const colors = getColor(percent);
  const filledHeight = 366 - 366 * (percent / 100);

  return (
    <>
      {metric.name === ATTENTION_METRIC && (
        <AttentionIcon
          width={width}
          height={height}
          filledColor={colors.filled}
          borderColor={colors.border}
          filledHeight={filledHeight}
        />
      )}
      {metric.name === COMPREHENSION_METRIC && (
        <ComprehensionIcon
          width={width}
          height={height}
          filledColor={colors.filled}
          borderColor={colors.border}
          filledHeight={filledHeight}
        />
      )}
    </>
  );
};
