import React, { FC } from 'react';
import { nanoid } from 'nanoid';

import { IconProps } from './AttentionIcon';

export const ComprehensionIcon: FC<IconProps> = ({ width, height, filledColor, borderColor, filledHeight }) => {
  const uuid = nanoid();
  return (
    <svg width={width} height={height} viewBox="0 0 266 366" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id={uuid} maskUnits="userSpaceOnUse" x="0" y={filledHeight} width="266" height="366">
        <path d="M285.04 33.77H0V391H285.04V33.77Z" fill={filledColor} />
      </mask>
      <g mask={`url(#${uuid})`}>
        <path
          d="M263.44 129.07C263.44 61.45 208.63 6.64001 141.01 6.64001C73.39 6.64001 18.58 61.45 18.58
          129.07C18.58 149.15 23.42 168.11 31.99 184.83C35.19 191.07 38.91 196.99 43.08 202.55C62.43
          230.65 74.78 236.87 76.58 297.98C76.85 307.25 84.45 314.62 93.72 314.62V335.17C93.72 349.76
          105.55 361.58 120.13 361.58H141.01H141.18H162.06C176.65 361.58 188.47 349.75 188.47
          335.17V314.62H188.64C197.91 314.62 205.51 307.25 205.78 297.98C207.9 225.88 224.71 230.2 250.38
          184.82H250.05C258.6 168.11 263.44 149.15 263.44 129.07Z"
          fill={filledColor}
        />
      </g>
      <path
        d="M162.05 367.6H120.13C102.26 367.6 87.71 353.06 87.71 335.18V319.85C78.02 317.3 70.88 308.68 70.57
        298.16C69.1 248.21 60.62 236.72 46.56 217.68C43.9 214.08 41.16 210.36 38.19 206.06C34.01 200.49 30.27
        194.56 27.07 188.42L27.06 188.41L26.63 187.57C26.58 187.48 26.53 187.38 26.49 187.29L25.98 186.27C17.08
        168.42 12.57 149.19 12.57 129.07C12.57 58.25 70.18 0.640015 141 0.640015C211.82 0.640015 269.43 58.25
        269.43 129.07C269.43 149.67 264.7 169.36 255.36 187.57L255.24 187.79C247.9 200.76 241.38 209.65 235.63
        217.5C221.66 236.56 213.23 248.06 211.75 298.17C211.44 308.75 204.22 317.4 194.45 319.9V335.18C194.46 353.06
        179.92 367.6 162.05 367.6ZM37.98 183.37C40.85 188.79 44.17 194.03 47.87 198.96L48.02 199.16C50.91 203.35
         53.61 207.02 56.23 210.57C71.14 230.78 80.99 244.13 82.57 297.82C82.75 303.89 87.64 308.64 93.71
         308.64H99.71V335.19C99.71 346.45 108.87 355.61 120.13 355.61H162.05C173.31 355.61 182.46 346.45
         182.46 335.19V308.64H188.63C194.7 308.64 199.59 303.89 199.77 297.82C201.36 243.98 211.15 230.62
         225.97 210.41C231.51 202.85 237.79 194.29 244.75 181.99C253.17 165.52 257.44 147.71 257.44 129.07C257.44
         64.87 205.21 12.64 141.01 12.64C76.81 12.64 24.58 64.87 24.58 129.07C24.58 147.44 28.73 164.99
          36.9 181.27L37.98 182.35V183.37Z"
        fill={borderColor}
      />
    </svg>
  );
};
