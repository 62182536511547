import React, { useEffect, useState } from 'react';
import amplitude from 'amplitude-js';
import lottie from 'lottie-web';

import './App.scss';
import { AMPLITUDE_API_KEY } from './consts';
import { WindowType } from './types';
import { Session } from './pages/session';
import { Analytics } from './pages/analytics';
import { BookAnalytics } from './pages/BookAnalytics';
import { ChapterAnalytics } from './pages/ChapterAnalytics';

/* url to dict with string for selected language
 */
const getLanguagesUrlTemplate = (lang: 'en' | 'ru') =>
  `https://oken-translations.s3.eu-central-1.amazonaws.com/strings/web/${lang}.json`;

function App() {
  const wnd = (window as unknown) as WindowType;
  const urlParams = new URLSearchParams(window.location.search);
  const pageName = urlParams.get('page');
  // getting language from url params or trying to get it from browser
  const currentLanguage = urlParams.get('lang') || window.navigator.language;

  wnd.token = urlParams.get('token') || '';
  wnd.userId = urlParams.get('userId') || undefined;
  wnd.amplitudeSessionId = urlParams.get('amplitude_sessionid');

  wnd.currentLang = 'en';
  if (currentLanguage.split('-')[0].match(/ru/i)) {
    wnd.currentLang = 'ru';
  }

  // initialize amplitude tracking client
  amplitude.getInstance().init(AMPLITUDE_API_KEY, wnd.userId);

  if (wnd.amplitudeSessionId) {
    amplitude.getInstance().setSessionId(+wnd.amplitudeSessionId);
  }

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isGlobalLoading, setIsGlobalLoading] = useState(true);
  const [inProcess, setInProcess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loaderElement = document.querySelector('#loader');

    if (loaderElement) {
      lottie.loadAnimation({
        container: loaderElement,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: '/loader_anim.json'
      });
    }

    fetch(getLanguagesUrlTemplate(wnd.currentLang)).then(async response => {
      wnd.langDict = await response.json();
      setIsGlobalLoading(false);
    });
  }, [wnd, currentLanguage]);

  const isSessionPage = ['session_metric', 'session', ''].includes(pageName || '');
  const isAnalyticsPage = pageName === 'aggregated';
  const isBookAnalyticsPage = pageName === 'book_aggregated';
  const isChapterAnalyticsPage = pageName === 'chapter_analytics';

  const isErrorPage = !isSessionPage && !isAnalyticsPage && !isBookAnalyticsPage && !isChapterAnalyticsPage;

  return (
    <>
      {!isErrorPage && (inProcess || isLoading || isGlobalLoading) && <div id="loader" />}
      {inProcess && <div className="inProcess">{wnd.langDict.web.loading_screen.analyzing}</div>}
      {error && <div className="error">{error}</div>}
      {!error && !isGlobalLoading && (
        <>
          {isSessionPage && <Session setIsLoading={setIsLoading} setInProcess={setInProcess} setError={setError} />}
          {isAnalyticsPage && <Analytics setIsLoading={setIsLoading} setInProcess={setInProcess} setError={setError} />}
          {isBookAnalyticsPage && (
            <BookAnalytics setIsLoading={setIsLoading} setInProcess={setInProcess} setError={setError} />
          )}
          {isChapterAnalyticsPage && (
            <ChapterAnalytics setIsLoading={setIsLoading} setInProcess={setInProcess} setError={setError} />
          )}
          {isErrorPage && <div className="error">{`${wnd.langDict.web.loading_screen.error} Not found`}</div>}
        </>
      )}
    </>
  );
}

export default App;
