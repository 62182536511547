import React from 'react';

import { getDasharray, getDasharrayInternal } from '../../helpers';

type InnerCircleBarProps = {
  percents: number;
};

const InnerCircleBar = (props: InnerCircleBarProps) => {
  const { percents } = props;
  const previousPercentDasharray = getDasharrayInternal(percents);

  if (percents > 0) {
    return (
      <circle
        cx="50"
        cy="50"
        r="46"
        stroke="rgba(0, 0, 0, 1)"
        strokeWidth="3"
        fill="none"
        strokeDasharray={`${previousPercentDasharray} 300`}
        strokeDashoffset="0"
        strokeMiterlimit="0"
        transform="rotate(-90) translate(-110 10)"
      />
    );
  } else {
    return <></>;
  }
};

type SimpleCircleGraphProps = {
  percents: number;
  className?: string;
  label?: string;
  bigLabel?: string;
  color?: string;
  prevPercents?: number;
};

export const SimpleCircleGraph = (props: SimpleCircleGraphProps) => {
  const { percents, prevPercents, color, bigLabel, label, className } = props;
  const percentDasharray = getDasharray(percents);

  return (
    <svg
      className={className}
      style={{ minWidth: '128px' }}
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      viewBox="0 0 120 120"
    >
      <circle
        cx="50"
        cy="50"
        r="55"
        stroke="rgba(0, 0, 0, 1)"
        strokeWidth="0.5"
        fill="none"
        transform="rotate(-90) translate(-110 10)"
      />
      {prevPercents !== undefined && <InnerCircleBar percents={prevPercents} />}
      {percents > 0 && (
        <circle
          cx="50"
          cy="50"
          r="51"
          stroke={color}
          strokeWidth="7"
          fill="none"
          strokeDasharray={`${percentDasharray} 1000`}
          strokeDashoffset="0"
          strokeMiterlimit="0"
          transform="rotate(-90) translate(-110 10)"
        />
      )}
      {bigLabel && (
        <text
          fontWeight="300"
          fontFamily="IBM Plex Mono"
          fontSize="28px"
          x="50%"
          y="45%"
          dominantBaseline="middle"
          textAnchor="middle"
          fill={color}
        >
          {bigLabel}
        </text>
      )}
      {label && (
        <text
          fontWeight="normal"
          fontFamily="IBM Plex Mono"
          fontSize="12px"
          x="50%"
          y="65%"
          dominantBaseline="middle"
          textAnchor="middle"
          fill="rgba(0, 0, 0, 0.6)"
        >
          {label}
        </text>
      )}
    </svg>
  );
};
