import React from 'react';
import cn from 'classnames';

import './Grid.scss';

type Props = {
  children?: React.ReactNode;
  className?: string;
};

export const Grid = (props: Props): JSX.Element => {
  return <div className={cn('Grid', props.className)}>{props.children}</div>;
};
