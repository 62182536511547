import React, { useCallback, useEffect, useRef, useState } from 'react';
import amplitude from 'amplitude-js';

import { SmallPageList } from '../../../components/SmallPage/SmallPageList';
import { Book } from '../../../components/Book/Book';
import { SessionType, MetricInfoType, WindowType, PageType } from '../../../types';
import { getMetricInfo } from '../../../helpers';
import { API_HOST, ATTENTION_METRIC, ALL, DEFECTIVE, PREV } from '../../../consts';
import reArrows from '../../../assets/img/re-arrows.svg';

import { MetricMessage } from './MetricMessage';

import './Metric.scss';

type Props = {
  onLoadCallback: () => void;
  session: SessionType;
  metricsInfo: MetricInfoType[];
};

const byOrder = (page1: PageType, page2: PageType): number => {
  if (page1.order > page2.order) {
    return 1;
  } else if (page1.order < page2.order) {
    return -1;
  }

  return 0;
};

export const Metric = (props: Props) => {
  const wrapperRef = useRef<React.ElementRef<'div'>>(null);

  const wnd = (window as unknown) as WindowType;

  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get('sessionId');

  const [isLoading, setIsLoading] = useState(true);
  const [pages, setPages] = useState(props.session.pages.sort(byOrder));
  const [currentPage, setCurrentPage] = useState<PageType>();
  const [currentPageId, setCurrentPageId] = useState(props.session.pages[0].id);

  useEffect(() => {
    fetch(`${API_HOST}/pages/${currentPageId}/`, {
      headers: { Authorization: `Bearer ${wnd.token}` }
    })
      .then(res => res.json())
      .then(page => {
        setCurrentPage(page);
      });
  }, [currentPageId]);

  const lowAttentionPages = props.session.pages
    .filter(page => {
      const attentionMetric = page.metrics.find(m => m.name === ATTENTION_METRIC);
      return attentionMetric && attentionMetric.value <= 0.33;
    })
    .sort(byOrder);

  const filterPages = useCallback(
    segment => {
      if (segment === ALL) {
        setPages(props.session.pages.sort(byOrder));
        setCurrentPageId(props.session.pages[0].id);
      } else if (segment === DEFECTIVE) {
        setPages(lowAttentionPages);
        setCurrentPageId(lowAttentionPages[0].id);
      }
    },
    [props.session]
  );

  const setPage = (pageId: string) => {
    setCurrentPageId(pageId);
    const currentPageIndex = pages.findIndex(page => page.id === pageId);
    wrapperRef.current?.scroll((currentPageIndex + 1) * 62 - window.innerWidth / 2, 0);

    amplitude.getInstance().logEvent('analytics detail: page switched', {
      sessionId,
      pageNumber: pageId,
      from: 'preview'
    });
  };

  const onArrowClick = (direction: string) => {
    const currentPageIndex = pages.findIndex(page => page.id === currentPageId);

    const increment = direction === PREV ? -1 : 1;
    const nextPageId = pages[currentPageIndex + increment].id;

    setPage(nextPageId);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    props.onLoadCallback();
    setIsLoading(false);

    amplitude.getInstance().logEvent('analytics overview: page-by-page metrics opened', { sessionId });
  }, [props, sessionId]);

  // const currentPage = pages.find(page => page.id === currentPageId);
  const currentAttentionMetric = currentPage?.metrics.find(m => m.name === ATTENTION_METRIC);
  const metricInfo = getMetricInfo(props.metricsInfo, ATTENTION_METRIC);
  const lowAttention = currentAttentionMetric && currentAttentionMetric.value <= 0.33;

  const isFirst = pages.findIndex(page => page.id === currentPage?.id) === 0;
  const isLast = pages.findIndex(page => page.id === currentPage?.id) === pages.length - 1;

  const goToPage = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();

    if (currentPage) {
      const href = `${API_HOST}/page/?cfi_begin=${currentPage.cfi_start}&book_id=${props.session.document_id}`;
      window.location.href = href;
    }
  };

  return !isLoading ? (
    <>
      <SmallPageList
        wrapperRef={wrapperRef}
        currentPageId={currentPageId}
        setFiltering={filterPages}
        hasFilters={!!lowAttentionPages.length}
        session={props.session}
        metricsInfo={props.metricsInfo}
        setCurrentPage={setPage}
        pages={pages}
      />

      {currentPage && lowAttention && (
        <span onClick={goToPage} className="Reread">
          <img src={reArrows} />
          &nbsp;{wnd.langDict.web.reading_session.read_again || 'Read again'}
        </span>
      )}

      {currentAttentionMetric && <MetricMessage metric={currentAttentionMetric} metricInfo={metricInfo} />}

      {currentPage && (
        <Book
          session={props.session}
          page={currentPage}
          onArrowClick={onArrowClick}
          isFirst={isFirst}
          isLast={isLast}
        />
      )}
    </>
  ) : null;
};
