import { ColorsType, ColorType, HMTimeType, MetricInfoType, MetricType } from './types';

export const getMetricInfo = (metricsInfo: MetricInfoType[], name: string): MetricInfoType => {
  const metric = metricsInfo.find(metric => metric.name === name);

  if (!metric) {
    throw new Error(`Unexpected metric name "${name}"`);
  }

  return metric;
};

export const getRawGradientColorBase = (value: number, colors1: ColorType, colors2: ColorType): ColorType => {
  const factor = (value < 0.5 ? value : value - 0.5) * 2;

  return [
    Math.round(colors1[0] + Math.abs(colors2[0] - colors1[0]) * factor * (colors1[0] > colors2[0] ? -1 : 1)),
    Math.round(colors1[1] + Math.abs(colors2[1] - colors1[1]) * factor * (colors1[1] > colors2[1] ? -1 : 1)),
    Math.round(colors1[2] + Math.abs(colors2[2] - colors1[2]) * factor * (colors1[2] > colors2[2] ? -1 : 1))
  ];
};

/* Defining color on base of values
 */
export const getRawGradientColor = (value: number, colors: ColorsType): ColorType => {
  if (value < 0.5) {
    return getRawGradientColorBase(value, colors['0'], colors['0.5']);
  } else if (value > 0.5) {
    return getRawGradientColorBase(value, colors['0.5'], colors['1']);
  } else {
    return colors['0.5'];
  }
};

/* Adding alpha to rgb color
 */
export const getColorWithAlpha = (color: number[], alpha = 1): string =>
  `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${alpha})`;

export const getColorOnBaseOfConfidence = (
  metricInfo: MetricInfoType,
  metric: MetricType,
  forceAlpha: number | undefined = undefined
) => {
  const realValue = metricInfo.metainfo.invert ? 1 - metric.value : metric.value;
  const confidenceValue = metric.confidence;
  const colorAlpha = forceAlpha !== undefined ? forceAlpha : Math.max(0.2, confidenceValue || 0);
  return getColorWithAlpha(getRawGradientColor(realValue, metricInfo.metainfo.colors), colorAlpha);
};

function jsonParseFunc<T>(data: string): T {
  return JSON.parse(data.replace(/NaN/g, 'null')) as T;
}

export const jsonParse = jsonParseFunc;

export const pluralFormat = (value: number, forms: string[], skipValue = false) => {
  if ([5, 6, 7, 8, 9, 0].includes(value % 10) || (value >= 11 && value <= 19)) {
    return `${skipValue ? '' : `${value} `}${forms[0]}`;
  } else if (value % 10 === 1) {
    return `${skipValue ? '' : `${value} `}${forms[1]}`;
  } else {
    // [2, 3, 4].includes(value % 10)
    return `${skipValue ? '' : `${value} `}${forms[2]}`;
  }
};

/* Calculating svg dash for external circle of graphs. Represents main value of the graph
 */
export const getDasharray = (percent: number) => {
  let dasharray = 3.25 * percent;

  if (percent >= 97) {
    dasharray -= 4;
  }

  return dasharray;
};

/* Calculating svg dash for external circle of graphs. Represents additional value of the graph
 */
export const getDasharrayInternal = (percent: number) => {
  let dasharray = 2.93 * percent;

  if (percent >= 97) {
    dasharray -= 4;
  }

  return dasharray;
};

export const getHMTimeFromSeconds = (time: number): HMTimeType => ({
  hours: Math.floor(time / 3600),
  minutes: Math.floor((time % 3600) / 60)
});
