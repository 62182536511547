export const AMPLITUDE_API_KEY = '82e05fa5221f327329de74898ed6cb74';

export let API_HOST = `https://api.oken.tech`;

if ('web.stg.oken.tech' === window.location.host) {
  API_HOST = 'https://api.stg.oken.tech';
} else if ('localhost:3000' === window.location.host || 'web.dev.oken.tech' === window.location.host) {
  API_HOST = 'https://api.dev.oken.tech';
}

export const ATTENTION_METRIC = 'comp_attention_arkit';
export const COMPREHENSION_METRIC = 'comp_understanding_arkit';
export const COMPLEXITY_METRIC = 'txt_complexity';

export const ALL = 'All pages';
export const DEFECTIVE = 'Defective pages';

export const PREV = 'prev';
export const NEXT = 'next';
