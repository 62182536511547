import React, { useCallback, useEffect, useState } from 'react';

import { AnalyticsType, WindowType } from '../../types';
import '../Page.scss';
import {
  getColorOnBaseOfConfidence,
  getHMTimeFromSeconds,
  jsonParse
  // pluralFormat
} from '../../helpers';
import { CircleTimeGraph } from '../../components/CircleTimeGraph';
import { CircleComplexityGraph } from '../../components/CircleComplexityGraph';
import { API_HOST, ATTENTION_METRIC, COMPLEXITY_METRIC, COMPREHENSION_METRIC } from '../../consts';
import { ReadingSpeedGraph } from '../../components/ReadingSpeedGraph';
import { BookCoverList } from '../../components/Book/BookCoverList';
import { EffectiveTime } from '../../components/EffectiveTime';
import { Grid } from '../../components/Grid';
import { MetricIcon } from '../../components/Icons';

import { PercentLine } from './PercentLine';

import './Analytics.scss';

type Props = {
  setIsLoading: (value: boolean) => void;
  setInProcess: (value: boolean) => void;
  setError: (error: string) => void;
};

type DateRange = {
  dtStart: string;
  dtEnd: string;
};

const getDateRanges = (dtStart: string, dtEnd: string): DateRange => {
  const dtStartCurrentWeek = new Date(dtStart);
  const dtEndCurrentWeek = new Date(dtEnd);

  return { dtStart: dtStartCurrentWeek.toISOString(), dtEnd: dtEndCurrentWeek.toISOString() };
};

export const Analytics = (props: Props) => {
  const [statsMutex, setStatsMutex] = useState(false);
  const [analytics, setAnalytics] = useState<AnalyticsType | null>(null);
  const [generalAnalytics, setGeneralAnalytics] = useState<AnalyticsType | null>(null);

  const wnd = (window as unknown) as WindowType;

  const urlParams = new URLSearchParams(window.location.search);
  const dtStart: string = urlParams.get('dt_start') || '';
  const dtEnd: string = urlParams.get('dt_end') || '';
  const dateRange = getDateRanges(dtStart, dtEnd);

  const loadingData = useCallback(() => {
    Promise.all([
      fetch(
        `${API_HOST}/analytics/sessions/?language=${wnd.currentLang}&dt_start=${dateRange.dtStart}` +
          `&dt_end_lte=${dateRange.dtEnd}`,
        { headers: { Authorization: `Bearer ${wnd.token}` } }
      ).then(async response => {
        const results = jsonParse<AnalyticsType>(await response.text());
        setAnalytics(results);
      }),
      fetch(`${API_HOST}/analytics/sessions/?language=${wnd.currentLang}`, {
        headers: { Authorization: `Bearer ${wnd.token}` }
      }).then(async response => {
        const results = jsonParse<AnalyticsType>(await response.text());
        setGeneralAnalytics(results);
      })
    ]).finally(() => {
      props.setInProcess(false);
      props.setIsLoading(false);
    });
  }, [wnd, dateRange, props]);

  useEffect(() => {
    if (!statsMutex) {
      setStatsMutex(true);
      loadingData();
    }
  }, [props, loadingData, statsMutex]);

  if (analytics === null || generalAnalytics === null) {
    return <></>;
  }

  const attentionMetric = analytics.total.metrics.find(item => item.name === ATTENTION_METRIC);
  const generalAttentionMetric = generalAnalytics.total.metrics.find(item => item.name === ATTENTION_METRIC);
  const comprehensionMetric = analytics.total.metrics.find(item => item.name === COMPREHENSION_METRIC);
  const generalComprehensionMetric = generalAnalytics.total.metrics.find(item => item.name === COMPREHENSION_METRIC);

  if (!attentionMetric || !comprehensionMetric) {
    return (
      <div className="noData">
        <div className="title">{wnd.langDict.analytics.week_overview.no_data.title}</div>
        <div>{wnd.langDict.analytics.week_overview.no_data.description}</div>
      </div>
    );
  }

  const effectiveReadingTime = Math.round(analytics.total.read_time * attentionMetric.value);
  const effectiveReadingHMTime = getHMTimeFromSeconds(effectiveReadingTime);

  const readingSpeed = analytics.total.read_speed * 60; // calculating per minute on base second result
  const generalReadingSpeed = generalAnalytics.total.read_speed * 60;
  const effectiveReadingSpeed = Math.round(readingSpeed * attentionMetric.value);

  const effectiveReadingAvgTime = Math.round(analytics.total.read_time_avg * attentionMetric.value);
  const effectiveReadingAvgHMTime = getHMTimeFromSeconds(effectiveReadingAvgTime);

  const txtComplexity = analytics.total.metrics.find(item => item.name === COMPLEXITY_METRIC);
  const generalTxtComplexity = generalAnalytics.total.metrics.find(item => item.name === COMPLEXITY_METRIC);

  return (
    <div className="webPage" id="analytics">
      <div id="mainMetrics">
        <div className="graphs">
          <div className="mainMetric">
            <h3>{attentionMetric.metainfo.display_name[wnd.currentLang]}</h3>
            <MetricIcon
              width={96}
              height={146}
              metric={{ ...attentionMetric, name: attentionMetric.name }}
              metricInfo={attentionMetric}
            />
          </div>
          <div className="mainMetric">
            <h3>{comprehensionMetric.metainfo.display_name[wnd.currentLang]}</h3>
            <MetricIcon
              width={96}
              height={146}
              metric={{ ...comprehensionMetric, name: comprehensionMetric.name }}
              metricInfo={comprehensionMetric}
            />
          </div>
        </div>

        {(generalAttentionMetric || generalComprehensionMetric) && (
          <div className="legend">
            <div className="week">
              <div className="sphere">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                  <circle
                    cx="12"
                    cy="6"
                    r="6"
                    fill={getColorOnBaseOfConfidence(
                      attentionMetric,
                      { ...attentionMetric, name: attentionMetric.name },
                      1
                    )}
                  />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12">
                  <circle
                    cx="0"
                    cy="6"
                    r="6"
                    fill={getColorOnBaseOfConfidence(
                      comprehensionMetric,
                      { ...comprehensionMetric, name: comprehensionMetric.name },
                      1
                    )}
                  />
                </svg>
              </div>
              <span>{wnd.langDict.web.aggregated.legend.week}</span>
            </div>

            <div className="average">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                <circle cx="6" cy="6" r="6" fill="rgba(255, 255, 255, 1)" />
              </svg>
              <span>{wnd.langDict.web.aggregated.legend.average}</span>
            </div>
          </div>
        )}
      </div>

      <Grid className="stats">
        <div className="card">
          <h3>{wnd.langDict.web.aggregated.words_read.title}</h3>
          <PercentLine value={analytics.total.words.read} previousValue={generalAnalytics.total.words.read} />
          <div className="value beforeEmoji">{analytics.total.words.read}</div>
          <div className="bottomBlockWrapper">
            <span className="emoji" role="img" aria-label="wordsRead" aria-labelledby="wordsRead">
              🤓
            </span>
            <div className="description grey">
              {wnd.langDict.web.aggregated.words_read.comparison
                .replace('%d', `${analytics.total.words.comparison.percent}`)
                .replace('%@', analytics.total.words.comparison.document.title)}
            </div>
          </div>
        </div>

        <div className="card">
          <h3>{wnd.langDict.web.aggregated.reading_time.title}</h3>
          <PercentLine value={analytics.total.read_time} previousValue={generalAnalytics.total.read_time} />
          <div className="circleGraph">
            <CircleTimeGraph effectiveTime={effectiveReadingTime} time={analytics.total.read_time} />
          </div>
          <div className="description">{wnd.langDict.web.aggregated.reading_time.comparison}</div>
          <div className="value">
            <EffectiveTime hmTime={effectiveReadingHMTime} />
          </div>
        </div>

        <div className="card">
          <h3 className="mt-20">{wnd.langDict.web.aggregated.reading_time_day.title}</h3>
          <PercentLine value={analytics.total.read_time_avg} previousValue={generalAnalytics.total.read_time_avg} />
          <div className="circleGraph">
            <CircleTimeGraph effectiveTime={effectiveReadingAvgTime} time={analytics.total.read_time_avg} />
          </div>
          <div className="description">{wnd.langDict.web.aggregated.reading_time_day.comparison}</div>
          <div className="value">
            <EffectiveTime hmTime={effectiveReadingAvgHMTime} />
          </div>
        </div>

        <div className="card">
          <h3 className="mt-20">{wnd.langDict.web.aggregated.reading_speed.title}</h3>
          <PercentLine value={readingSpeed} previousValue={generalReadingSpeed} />
          <div className="circleGraph">
            <ReadingSpeedGraph effectiveValue={effectiveReadingSpeed} value={readingSpeed} />
          </div>
          {/*<div className="description">{wnd.langDict.web.aggregated.reading_speed.comparison}</div>
          <div className="value">
            <span className="greenValue">{effectiveReadingSpeed}</span>
            <span className="small" style={wnd.currentLang === 'en' ? { width: '70px' } : { width: '46px' }}>
              {pluralFormat(
                effectiveReadingSpeed,
                [
                  wnd.langDict.web.aggregated.reading_speed.unit.zero,
                  wnd.langDict.web.aggregated.reading_speed.unit.one,
                  wnd.langDict.web.aggregated.reading_speed.unit.other
                ],
                true
              )}
            </span>
          </div>*/}
        </div>

        {txtComplexity && (
          <div className="card">
            <h3>{wnd.langDict.web.aggregated.readability.title}</h3>
            <div className="circleGraph readability">
              <CircleComplexityGraph
                metric={{ value: txtComplexity.value, name: txtComplexity.name, confidence: 1 }}
                previousMetric={
                  generalTxtComplexity && {
                    value: generalTxtComplexity.value,
                    name: generalTxtComplexity.name,
                    confidence: 1
                  }
                }
                metricInfo={txtComplexity}
              />
            </div>
          </div>
        )}
      </Grid>

      {analytics.documents.length > 0 && (
        <BookCoverList header={wnd.langDict.analytics.opened_books} books={analytics.documents} />
      )}
    </div>
  );
};
