/* Group of main types for the application. Main quality controller at the moment

Structure of data in the application:
- user can read several documents
- each reading session of a document creates session record
- session record consists one or several pages
- each page consists several fragments (can be treated as paragraphs)

Everything has metrics:
- document can have aggregated metrics
- session has average metrics of pages
- page has average metrics of fragments
- fragment have metrics

Additionally user has aggregated metrics across several documents.
 */

/* Metric is:
- metric - name string which can be mapped with `metainfo` (will be described below)
- value - value metric, number
- confidence - can be found for some of the metrics (`confidence` flag in `metainfo`), number.
    Describes how confident we are in `value`
 */
export type MetricType = {
  value: number;
  confidence: number;
  name: string;
};

export type FragmentType = {
  id: string;
  openTime: number;
  closeTime: number;
  page: string;
  content: string;
};

export type PageType = {
  id: string;
  content: string;
  cfi_start: string;
  cfi_end: string;
  closeTime: number;
  metrics: SessionMetricType[];
  // openTime: number;
  dt_start: string;
  dt_end: string;
  metainfo: {
    orientation: 'portrait' | 'landscape';
    font: 'IBM Plex Serif' | 'SF Pro Text';
    deviceTurned: false | 'left' | 'right';
    page_no: string;
    textSize: number;
    style: 'classic' | 'beige' | 'dark';
  };
  order: number;
};

export type PagesType = PageType[];

export type deviceType =
  | 'iPhone10,3'
  | 'iPhone10,6'
  | 'iPhone11,2'
  | 'iPhone11,4'
  | 'iPhone11,6'
  | 'iPhone11,8'
  | 'iPhone12,1'
  | 'iPhone12,3'
  | 'iPhone12,5'
  | 'iPhone13,1'
  | 'iPhone13,2'
  | 'iPhone13,3'
  | 'iPhone13,4'
  | 'iPad8,1'
  | 'iPad8,2'
  | 'iPad8,3'
  | 'iPad8,4'
  | 'iPad8,5'
  | 'iPad8,6'
  | 'iPad8,7'
  | 'iPad8,8'
  | 'iPad8,9'
  | 'iPad8,10'
  | 'iPad8,11'
  | 'iPad8,12'
  | 'iPad13,4'
  | 'iPad13,5'
  | 'iPad13,6'
  | 'iPad13,7'
  | 'iPad13,8'
  | 'iPad13,9'
  | 'iPad13,10'
  | 'iPad13,11';

type DimensionsType = {
  readingAreaWidth: string;
  readingAreaHeight: string;
  readingAreaOffset_x: string;
  readingAreaOffset_y: string;
};

export type SessionType = {
  id: number;
  is_processed: boolean;
  is_failed: boolean;
  reading_time: number;
  words: number;
  dt_start: string;
  dt_end: string;
  document_id: string;
  pages: PagesType;
  metrics: SessionMetricType[];
  device: {
    deviceModelName: deviceType;
    screenWidth: number;
    screenHeight: number;
    dimensions: {
      portrait: DimensionsType;
      landscape: DimensionsType;
    };
  };
};

export type ColorType = number[];

/* Color is defined as 3 rgba values for metric.value === 0, metric.value === 0.5 and metric.value === 1.
Everything between these values can be calculated as a gradient.
 */
export type ColorsType = {
  '0': ColorType;
  '0.5': ColorType;
  '1': ColorType;
};

/* Metric info - can be gathered from separate api endpoint. All metrics are defined and configured on backend side.
`metricInfo.name` can be compared with `metric.metric` to find info data.

- order - position of the metric in lists. 0 on top
- name - metric constant name string
- metainfo - additional info for the metric
  - confidence - does the metric have confidence value?
  - label_x - label for complexity strings in graphs
  - display_name - readable string of the metric
  - invert - if `true` than value should be inverted (bigger is worse)
  - colors - color values
  - icon - icon file name
 */
export type BaseMetricInfoType = {
  display_name: {
    en: string;
    ru: string;
  };
  icon: 'engagement.svg' | 'comprehension.svg' | 'confidence.svg' | 'tiredness.svg';
  invert: boolean;
  colors: ColorsType;
};

export type MetricInfoType = {
  name: string;
  metainfo: BaseMetricInfoType & {
    confidence: boolean;
    label_0?: { en: string; ru: string };
    label_1?: { en: string; ru: string };
    inline_name: { en: string; ru: string };
    explanations: {
      '0': { en: string; ru: string };
      '0.4': { en: string; ru: string };
      '0.6': { en: string; ru: string };
    };
  };
  order: number;
};

export type ConfidenceInfoType = {
  metainfo: BaseMetricInfoType;
};

export type SessionMetricType = MetricType & {
  score: number | null;
  comment: string | null;
};

export type PageMetric = {
  id: string;
  order: number;
  metrics: MetricType[];
};

/* Document description (backend response contains more information but it's not important for the app)

- title - hmm... it's title :)
- author
- original_book_url - url to original book file (to download token in headers should be sent)
- okenized_book_url - url to processed book file by okenizer service.
 */
export type ResultDocumentType = {
  title: string;
  author: string;
  okenized_book_url: string;
  original_book_url: string;
};

type ResultsMetricsType = {
  metrics: SessionMetricType[];
};

/* Session result type structure

- is_processed - is the session processed or still in progress
- is_failed - is it successful or not
- document_url - document stats url (not to document itself)
- metrics_values_url - all metric values for the session
- device_data_url - raw device data
- pages_url - pages data
- fragments_url - fragments data
- session_url - session specific data
- session - common session data
- document - common document data
- read_time - reading time in seconds
- pages - common pages info
 */
export type ResultsType = {
  is_processed: boolean;
  is_failed: boolean;
  document_url: string;
  metrics_values_url: string;
  device_data_url: string;
  pages_url: string;
  fragments_url: string;
  session_url: string;
  session: ResultsMetricsType;
  document: ResultDocumentType;
  read_time: number;
  pages: PageMetric[];
};

/* Since we don't use any state management tool everything is stored in global window object.

- token - auth token which is passed in url
- userId - optional user for tracking info
- amplitudeSessionId - tacking session id
- currentLang - current language
- innerWidth - width of client area
- innerHeight - height of client area
- langDict - language structure. Is loaded separately on global level of application. This structure is built
    automatically by updating another repository with strings
 */
export type WindowType = {
  token: string;
  userId?: string;
  amplitudeSessionId: string | null;
  currentLang: 'en' | 'ru';
  innerWidth: number;
  innerHeight: number;
  langDict: {
    web: {
      aggregated: {
        time_estimate: {
          description: string;
          title: {
            not_started: string;
            started: string;
          };
        };
        chapter: {
          failed: string;
        };
        legend: {
          average: string;
          day: string;
          month: string;
          week: string;
        };
        readability: {
          comparison: string;
          title: string;
        };
        reading_speed: {
          comparison: string;
          title: string;
          unit: {
            one: string;
            other: string;
            zero: string;
          };
        };
        reading_time: {
          comparison: string;
          title: string;
        };
        reading_time_day: {
          comparison: string;
          title: string;
        };
        words_read: {
          comparison: string;
          comparison_chapter: string;
          title: string;
        };
      };
      loading_screen: {
        analyzing: string;
        error: string;
        processing_failed: string;
      };
      reading_session: {
        headline: string;
        start: string;
        time_elapsed: string;
        details_button: string;
        page_skipped: string;
        read: string;
        read_again: string;
        page_analytics: string;
        all_pages: string;
        low_attention_pages: string;
        report: {
          description: string;
          placeholder: string;
          submit: string;
          title: string;
        };
        word_count: {
          one: string;
          other: string;
          zero: string;
        };
        page_count: {
          one: string;
          other: string;
          zero: string;
        };
      };
    };
    time: {
      hour: {
        long: {
          one: string;
          other: string;
          zero: string;
        };
        short: string;
      };
      minute: {
        long: {
          one: string;
          other: string;
          zero: string;
        };
        short: string;
      };
    };
    analytics: {
      opened_books: string;
      week_overview: {
        no_data: {
          description: string;
          title: string;
        };
      };
      percent_explanation: string;
    };
  };
};

export type ExtendedMetricInfoType = MetricInfoType & {
  value: number;
  confidence: number;
};

export type DocumentType = {
  id: number;
  files: {
    cover: string;
    okenized: string;
  };
  authors: Array<string>;
  downloadUrls: Array<string>;
  formatType: string;
  hash: string;
  okenized_book_url: string;
  original_book_url: string;
  title: string;
  words: {
    total: number;
  };
};

export type AnalyticsDocumentType = {
  id: number;
  cover_url: string;
  name: string;
  read_prediction: number;
  read_speed: number;
  read_time: number;

  words: {
    total: number;
    read: number;
    comparison: {
      document: {
        title: string;
        words: number;
      };
      percent: number;
    };
  };
};

export type AnalyticsType = {
  documents: AnalyticsDocumentType[];
  total: {
    metrics: ExtendedMetricInfoType[];
    read_speed: number;
    read_time: number;
    read_time_avg: number;
    txt_complexity: number;
    words: {
      read: number;
      comparison: {
        percent: number;
        document: {
          title: string;
          words: number;
        };
      };
    };
  };
};

export enum ChapterStatus {
  NOT_READ = 'not_read',
  IN_PROGRESS = 'partially_read',
  DONE = 'read'
}

export type ChapterType = {
  id: string;
  cfi_end: string;
  cfi_start: string;
  children: string[];
  metrics: ExtendedMetricInfoType[];
  reading_prediction: number;
  reading_time: number;
  reading_speed: number;
  src: string;
  status: ChapterStatus;
  title: string;
  words: {
    total: number;
    read: number;
    comparison: {
      percent: number;
      document: {
        title: string;
        words: number;
      };
    };
  };
  words_count: number;
};

export type UserType = {
  id: number;
  dt_create: string;
  dt_update: string;
  first_name: string;
  last_name: string;
  email: string;
  uid: string;
  reading_speed: number | null;
  date_joined: string;
  is_new: false;
  reading_time: number;
  reading_words: number;
  service_id: number;
};

export type HMTimeType = {
  hours: number;
  minutes: number;
};
