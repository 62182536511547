import React from 'react';

import { pagesText } from '../../../components/Book/helpers';
import { WindowType, SessionType, MetricInfoType, DocumentType } from '../../../types';
import bookSvg from '../../../assets/img/book.svg';

import { DetailsMetricGraph } from './DetailsMetricGraph';

import '../../Page.scss';
import './Details.scss';

type Props = {
  setIsLoading: (isLoading: boolean) => void;
  session: SessionType;
  metricsInfo: MetricInfoType[];
  document?: DocumentType;
};

const twoSymbols = (value: number): string => {
  return value.toString().length === 1 ? `0${value}` : `${value}`;
};

const formatDate = (ts: string): string => {
  const date = new Date(ts);
  return (
    twoSymbols(date.getDate()) +
    '.' +
    twoSymbols(date.getMonth() + 1) +
    '.' +
    `${date.getFullYear()}` +
    ', ' +
    twoSymbols(date.getHours()) +
    ':' +
    twoSymbols(date.getMinutes())
  );
};

const formatDuration = (duration: number): string => {
  const minutes = Math.floor(duration / 60);
  return `${twoSymbols(minutes)}:${twoSymbols(Math.floor(duration - 60 * minutes))}`;
};

export const Details = (props: Props) => {
  const wnd = (window as unknown) as WindowType;
  const urlParams = new URLSearchParams(window.location.search);

  const readingTime = props.session.reading_time;
  document.title = wnd.langDict.web.reading_session.headline;

  const metrics = props.session.metrics.filter(sessionMetric =>
    props.metricsInfo.find(existingMetric => existingMetric.name === sessionMetric.name)
  );

  metrics.sort((metric1, metric2) => {
    const metricInfo1 = props.metricsInfo.find(existingMetric => existingMetric.name === metric1.name);
    const metricInfo2 = props.metricsInfo.find(existingMetric => existingMetric.name === metric2.name);

    if (metricInfo1 && metricInfo2) {
      if (metricInfo1.order > metricInfo2.order) {
        return 1;
      } else if (metricInfo1.order < metricInfo2.order) {
        return -1;
      }
    }

    return 0;
  });

  const openMetric = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const token = urlParams.get('token');
    const sessionId = urlParams.get('sessionId');
    const amplitude_sessionid = urlParams.get('amplitude_sessionid');

    props.setIsLoading(true);

    window.location.href =
      `?page=session_metric&token=${token}&sessionId=${sessionId}` + `&amplitude_sessionid=${amplitude_sessionid}`;
  };

  return (
    <div className="Details webPage">
      <h1>{wnd.langDict.web.reading_session.headline}</h1>

      {props.document && (
        <div className="__description">
          <img className="__cover" src={props.document.files.cover} alt="Book cover" />
          <p className="__author">{props.document.authors.join(', ')}</p>
          <h2 className="__title">{props.document.title}</h2>
        </div>
      )}

      <div className="props">
        <p>
          <span>{wnd.langDict.web.reading_session.start}</span>
          <span>{formatDate(props.session.pages[0].dt_start)}</span>
        </p>
        <p>
          <span>{wnd.langDict.web.reading_session.time_elapsed}</span>
          <span>{formatDuration(readingTime)}</span>
        </p>
        <p className="pages">
          <span>{wnd.langDict.web.reading_session.read || 'Read'}</span>
          <span>{pagesText(props.session.pages.length)}</span>
        </p>
      </div>

      <span onClick={openMetric} className="__page-analytics-link">
        <img src={bookSvg} />
        &nbsp;{wnd.langDict.web.reading_session.page_analytics || 'Page analytics'}
      </span>

      <hr />

      {metrics.map(metric => (
        <DetailsMetricGraph session={props.session} metricsInfo={props.metricsInfo} key={metric.name} metric={metric} />
      ))}
    </div>
  );
};
