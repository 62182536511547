import React from 'react';

import { Grid } from '../../components/Grid';
import { ChapterType, WindowType } from '../../types';
import { ReadingSpeedGraph } from '../../components/ReadingSpeedGraph';
import { SimpleCircleGraph } from '../../components/SimpleCircleGraph';
import { pluralFormat } from '../../helpers';
import { CircleGraph } from '../../components/CircleGraph';
import { ATTENTION_METRIC, COMPREHENSION_METRIC } from '../../consts';

type Props = {
  chapter: ChapterType;
};

export const Done = (props: Props): JSX.Element => {
  const { chapter } = props;
  const wnd = (window as unknown) as WindowType;

  const readingSpeedInMinutes = chapter.reading_speed * 60;
  const readTime = chapter.reading_time / 60;

  const comprehensionMetric = chapter.metrics?.find(item => item.name === COMPREHENSION_METRIC);
  const attentionMetric = chapter.metrics?.find(item => item.name === ATTENTION_METRIC);
  const hasMetrics = attentionMetric || comprehensionMetric;
  const effectiveReadingSpeed = attentionMetric ? Math.round(readingSpeedInMinutes * attentionMetric.value) : 0;
  const effectiveReadingTime = attentionMetric ? Math.round(readTime * attentionMetric.value) : 0;

  const effectiveTimeProportion = effectiveReadingTime / readTime;

  return (
    <div className="Done">
      {hasMetrics && (
        <div className="graphs">
          {attentionMetric && (
            <div className="mainMetric">
              <h3>{attentionMetric?.metainfo.display_name[wnd.currentLang]}</h3>
              <CircleGraph
                isDark={true}
                isBig={true}
                metricInfo={attentionMetric}
                metric={{ ...attentionMetric, name: attentionMetric.name }}
              />
            </div>
          )}
          {comprehensionMetric && (
            <div className="mainMetric">
              <h3>{comprehensionMetric.metainfo.display_name[wnd.currentLang]}</h3>
              <CircleGraph
                isDark={true}
                isBig={true}
                metricInfo={comprehensionMetric}
                metric={{ ...comprehensionMetric, name: comprehensionMetric.name }}
              />
            </div>
          )}
        </div>
      )}

      <Grid>
        <div className="card">
          <h3>{wnd.langDict.web.aggregated.words_read.title}</h3>
          <div className="words-count">{chapter.words.read.toString()}</div>
          <span className="emoji" role="img" aria-label="wordsRead" aria-labelledby="wordsRead">
            🤓
          </span>
          <div className="description grey">
            {/* TODO: add proper comparison */}
            {wnd.langDict.web.aggregated.words_read.comparison
              .replace('%d', `${chapter.words.comparison.percent}`)
              .replace('%@', chapter.words.comparison.document.title)}
          </div>
        </div>

        <div className="card">
          <h3>{wnd.langDict.web.aggregated.reading_speed.title}</h3>
          <div className="circle-graph --effective-speed">
            <ReadingSpeedGraph effectiveValue={effectiveReadingSpeed} value={readingSpeedInMinutes} />
          </div>
          {/* <div className="description">{wnd.langDict.web.aggregated.reading_speed.comparison}</div>
          <div className="value">
            <span className="green">{effectiveReadingSpeed}</span>
            <span className="small" style={wnd.currentLang === 'en' ? { width: '70px' } : { width: '46px' }}>
              {pluralFormat(
                effectiveReadingSpeed,
                [
                  wnd.langDict.web.aggregated.reading_speed.unit.zero,
                  wnd.langDict.web.aggregated.reading_speed.unit.one,
                  wnd.langDict.web.aggregated.reading_speed.unit.other
                ],
                true
              )}
            </span>
          </div>*/}
        </div>

        <div className="card">
          <h3 className="mt-20">{wnd.langDict.web.reading_session.time_elapsed}</h3>

          <SimpleCircleGraph
            percents={effectiveTimeProportion * 100}
            className="circle-graph --effective-time"
            color="#000000"
            bigLabel={Math.round(readTime).toString()}
            label={pluralFormat(
              readTime,
              [
                wnd.langDict.time.minute.long.zero,
                wnd.langDict.time.minute.long.one,
                wnd.langDict.time.minute.long.other
              ],
              true
            )}
          />
          <div className="description">{wnd.langDict.web.aggregated.reading_time.comparison}</div>
          <div className="hrs-mins">
            <span className="digit">{effectiveReadingTime}</span>
            <span className="unit">
              {pluralFormat(
                effectiveReadingTime,
                [
                  wnd.langDict.time.minute.long.zero,
                  wnd.langDict.time.minute.long.one,
                  wnd.langDict.time.minute.long.other
                ],
                true
              )}
            </span>
          </div>
        </div>
      </Grid>
    </div>
  );
};
