import React, { useCallback, useEffect, useState } from 'react';

import './BookAnalytics.scss';
import { AnalyticsType, WindowType } from '../../types';
import { API_HOST, ATTENTION_METRIC, COMPLEXITY_METRIC, COMPREHENSION_METRIC } from '../../consts';
import { getColorOnBaseOfConfidence, getHMTimeFromSeconds, jsonParse, pluralFormat } from '../../helpers';
import { Grid } from '../../components/Grid';
import { CircleTimeGraph } from '../../components/CircleTimeGraph';
import { EffectiveTime } from '../../components/EffectiveTime';
import { ReadingSpeedGraph } from '../../components/ReadingSpeedGraph';
import { CircleComplexityGraph } from '../../components/CircleComplexityGraph';
import { MetricIcon } from '../../components/Icons';

type Props = {
  setIsLoading: (value: boolean) => void;
  setInProcess: (value: boolean) => void;
  setError: (error: string) => void;
};

export const BookAnalytics = (props: Props): JSX.Element | null => {
  const [statsMutex, setStatsMutex] = useState(false);
  const [bookAnalytics, setBookAnalytics] = useState<AnalyticsType | null>(null);
  const [generalAnalytics, setGeneralAnalytics] = useState<AnalyticsType | null>(null);

  const wnd = (window as unknown) as WindowType;

  const urlParams = new URLSearchParams(window.location.search);
  const documentId: string = urlParams.get('documentId') || '';

  const loadingData = useCallback(() => {
    Promise.all([
      fetch(`${API_HOST}/analytics/sessions/?language=${wnd.currentLang}&document=${documentId}`, {
        headers: { Authorization: `Bearer ${wnd.token}` }
      }).then(async response => {
        const results = jsonParse<AnalyticsType>(await response.text());
        setBookAnalytics(results);
      }),
      fetch(`${API_HOST}/analytics/sessions/?language=${wnd.currentLang}`, {
        headers: { Authorization: `Bearer ${wnd.token}` }
      }).then(async response => {
        const results = jsonParse<AnalyticsType>(await response.text());
        setGeneralAnalytics(results);
      })
    ]).finally(() => {
      props.setInProcess(false);
      props.setIsLoading(false);
    });
  }, [wnd, documentId, props]);

  useEffect(() => {
    if (!statsMutex) {
      setStatsMutex(true);
      loadingData();
    }
  }, [props, loadingData, statsMutex]);

  if (bookAnalytics === null || generalAnalytics === null) {
    return <></>;
  }

  const attentionMetric = bookAnalytics.total.metrics.find(item => item.name === ATTENTION_METRIC);
  const comprehensionMetric = bookAnalytics.total.metrics.find(item => item.name === COMPREHENSION_METRIC);
  const generalAttentionMetric = generalAnalytics.total.metrics.find(item => item.name === ATTENTION_METRIC);
  const generalComprehensionMetric = generalAnalytics.total.metrics.find(item => item.name === COMPREHENSION_METRIC);

  if (!attentionMetric || !comprehensionMetric) {
    return (
      <div className="noData">
        <div className="title">{wnd.langDict.analytics.week_overview.no_data.title}</div>
        <div>{wnd.langDict.analytics.week_overview.no_data.description}</div>
      </div>
    );
  }

  const effectiveReadingTime = Math.round(bookAnalytics.total.read_time * attentionMetric.value);
  const effectiveReadingHMTime = getHMTimeFromSeconds(effectiveReadingTime);

  const readingSpeed = bookAnalytics.total.read_speed * 60; // calculating per minute on base second result
  const effectiveReadingSpeed = Math.round(readingSpeed * attentionMetric.value);

  const txtComplexity = bookAnalytics.total.metrics.find(item => item.name === COMPLEXITY_METRIC);
  const generalTxtComplexity = generalAnalytics.total.metrics.find(item => item.name === COMPLEXITY_METRIC);

  const commonReadingSpeed = generalAnalytics.total.read_speed * 60;
  const timeLeft =
    commonReadingSpeed > 0
      ? Math.round((bookAnalytics.documents[0].words.total - bookAnalytics.total.words.read) / commonReadingSpeed)
      : 0;
  const timeLeftHours = Math.floor(timeLeft / 60);
  const timeLeftMinutes = timeLeft % 60;
  const roundedCommonReadingSpeed = Math.round(commonReadingSpeed);

  if (bookAnalytics.total.words.read > 0) {
    return (
      <div className="BookAnalytics">
        <div id="mainMetrics">
          <div className="graphs">
            <div className="mainMetric">
              <h3>{attentionMetric.metainfo.display_name[wnd.currentLang]}</h3>
              <MetricIcon
                width={96}
                height={146}
                metric={{ ...attentionMetric, name: attentionMetric.name }}
                metricInfo={attentionMetric}
              />
            </div>
            <div className="mainMetric">
              <h3>{comprehensionMetric.metainfo.display_name[wnd.currentLang]}</h3>
              <MetricIcon
                width={96}
                height={146}
                metric={{ ...attentionMetric, name: attentionMetric.name }}
                metricInfo={attentionMetric}
              />
            </div>
          </div>

          {(generalAttentionMetric || generalComprehensionMetric) && (
            <div className="legend">
              <div className="week">
                <div className="sphere">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                    <circle
                      cx="12"
                      cy="6"
                      r="6"
                      fill={getColorOnBaseOfConfidence(
                        attentionMetric,
                        { ...attentionMetric, name: attentionMetric.name },
                        1
                      )}
                    />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12">
                    <circle
                      cx="0"
                      cy="6"
                      r="6"
                      fill={getColorOnBaseOfConfidence(
                        comprehensionMetric,
                        { ...comprehensionMetric, name: comprehensionMetric.name },
                        1
                      )}
                    />
                  </svg>
                </div>
                <span>{wnd.langDict.web.aggregated.legend.week}</span>
              </div>

              <div className="average">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                  <circle cx="6" cy="6" r="6" fill="rgba(255, 255, 255, 1)" />
                </svg>
                <span>{wnd.langDict.web.aggregated.legend.average}</span>
              </div>
            </div>
          )}
        </div>

        <Grid className="stats">
          {/* top left */}
          <div className="card">
            <h3>{wnd.langDict.web.aggregated.words_read.title}</h3>
            <div className="value beforeEmoji">{bookAnalytics.total.words.read}</div>
            <div className="bottomBlockWrapper">
              <span className="emoji" role="img" aria-label="wordsRead" aria-labelledby="wordsRead">
                🤓
              </span>
              <div className="description grey">
                {wnd.langDict.web.aggregated.words_read.comparison
                  .replace('%d', `${bookAnalytics.total.words.comparison.percent}`)
                  .replace('%@', bookAnalytics.total.words.comparison.document.title)}
              </div>
            </div>
          </div>

          {/* top right */}
          <div className="card">
            <h3>{wnd.langDict.web.aggregated.reading_time.title}</h3>
            <div className="circleGraph">
              <CircleTimeGraph effectiveTime={effectiveReadingTime} time={bookAnalytics.total.read_time} />
            </div>
            <div className="description">{wnd.langDict.web.aggregated.reading_time.comparison}</div>
            <div className="value">
              <EffectiveTime hmTime={effectiveReadingHMTime} />
            </div>
          </div>

          {/* bottom left */}
          <div className="card">
            <h3 className="mt-20">{wnd.langDict.web.aggregated.time_estimate.title.started}</h3>
            <div className="value beforeEmoji">
              {timeLeftHours > 0 && (
                <div className="predictionHours">
                  {timeLeftHours}
                  <span>
                    {pluralFormat(
                      timeLeftHours,
                      [
                        wnd.langDict.time.hour.long.zero,
                        wnd.langDict.time.hour.long.one,
                        wnd.langDict.time.hour.long.other
                      ],
                      true
                    )}
                  </span>
                </div>
              )}
              {(timeLeftMinutes > 0 || timeLeftHours === 0) && (
                <div className="predictionMinutes">
                  {timeLeftMinutes}
                  <span>
                    {pluralFormat(
                      timeLeftMinutes,
                      [
                        wnd.langDict.time.minute.long.zero,
                        wnd.langDict.time.minute.long.one,
                        wnd.langDict.time.minute.long.other
                      ],
                      true
                    )}
                  </span>
                </div>
              )}
            </div>
            <div className="bottomBlockWrapper">
              <span className="emoji" role="img" aria-label="wordsRead" aria-labelledby="wordsRead">
                ⏱
              </span>
              <div className="description grey">
                {wnd.langDict.web.aggregated.time_estimate.description.replace(
                  '%@',
                  `${roundedCommonReadingSpeed} ${
                    roundedCommonReadingSpeed > 1
                      ? wnd.langDict.web.aggregated.reading_speed.unit.other
                      : wnd.langDict.web.aggregated.reading_speed.unit.one
                  }`
                )}
              </div>
            </div>
          </div>

          {/* bottom right */}
          <div className="card">
            <h3 className="mt-20">{wnd.langDict.web.aggregated.reading_speed.title}</h3>
            <div className="circleGraph">
              <ReadingSpeedGraph effectiveValue={effectiveReadingSpeed} value={readingSpeed} />
            </div>
            {/* <div className="description">{wnd.langDict.web.aggregated.reading_speed.comparison}</div>
            <div className="value">
              <span className="greenValue">{effectiveReadingSpeed}</span>
              <span className="small" style={wnd.currentLang === 'en' ? { width: '70px' } : { width: '46px' }}>
                {pluralFormat(
                  effectiveReadingSpeed,
                  [
                    wnd.langDict.web.aggregated.reading_speed.unit.zero,
                    wnd.langDict.web.aggregated.reading_speed.unit.one,
                    wnd.langDict.web.aggregated.reading_speed.unit.other
                  ],
                  true
                )}
              </span>
            </div> */}
          </div>

          {txtComplexity && (
            <div className="card">
              <h3 className="mt-20">{wnd.langDict.web.aggregated.readability.title}</h3>
              <div className="circleGraph readability">
                <CircleComplexityGraph
                  metric={{ value: txtComplexity.value, name: txtComplexity.name, confidence: 1 }}
                  metricInfo={txtComplexity}
                  previousMetric={
                    generalTxtComplexity && {
                      value: generalTxtComplexity.value,
                      name: generalTxtComplexity.name,
                      confidence: 1
                    }
                  }
                />
              </div>
            </div>
          )}
        </Grid>
      </div>
    );
  } else {
    return (
      <div className="webPage" id="bookAnalytics">
        <Grid>
          <div className="card">
            <h3>{wnd.langDict.web.aggregated.time_estimate.title.not_started}</h3>
            <div className="value beforeEmoji">
              {timeLeftHours > 0 && (
                <div className="predictionHours">
                  {timeLeftHours}
                  <span>
                    {pluralFormat(
                      timeLeftHours,
                      [
                        wnd.langDict.time.hour.long.zero,
                        wnd.langDict.time.hour.long.one,
                        wnd.langDict.time.hour.long.other
                      ],
                      true
                    )}
                  </span>
                </div>
              )}
              {(timeLeftMinutes > 0 || timeLeftHours === 0) && (
                <div className="predictionMinutes">
                  {timeLeftMinutes}&nbsp;
                  <span>
                    {pluralFormat(
                      timeLeftMinutes,
                      [
                        wnd.langDict.time.minute.long.zero,
                        wnd.langDict.time.minute.long.one,
                        wnd.langDict.time.minute.long.other
                      ],
                      true
                    )}
                  </span>
                </div>
              )}
            </div>
            <div className="bottomBlockWrapper">
              <span className="emoji" role="img" aria-label="wordsRead" aria-labelledby="wordsRead">
                ⏱
              </span>
              <div className="description grey">
                {wnd.langDict.web.aggregated.time_estimate.description.replace(
                  '%@',
                  pluralFormat(roundedCommonReadingSpeed, [
                    wnd.langDict.web.aggregated.reading_speed.unit.zero,
                    wnd.langDict.web.aggregated.reading_speed.unit.one,
                    wnd.langDict.web.aggregated.reading_speed.unit.other
                  ])
                )}
              </div>
            </div>
          </div>
          <div className="card"></div>
        </Grid>
      </div>
    );
  }
};
