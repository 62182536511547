import React from 'react';

import { getDasharray, pluralFormat } from '../../helpers';
import { WindowType } from '../../types';

type ReadingSpeedGraphProps = {
  effectiveValue: number;
  value: number;
};

export const ReadingSpeedGraph = (props: ReadingSpeedGraphProps) => {
  const wnd = (window as unknown) as WindowType;

  const percent = props.value === 0 ? 0 : Math.round((props.effectiveValue / props.value) * 100);
  const percentDasharray = getDasharray(percent);

  const valueString = pluralFormat(
    props.effectiveValue,
    [
      wnd.langDict.web.aggregated.reading_speed.unit.zero,
      wnd.langDict.web.aggregated.reading_speed.unit.one,
      wnd.langDict.web.aggregated.reading_speed.unit.other
    ],
    true
  );

  return (
    <svg
      style={{ minWidth: '128px' }}
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      viewBox="0 0 120 120"
    >
      <circle
        cx="50"
        cy="50"
        r="55"
        stroke="rgba(0, 0, 0, 1)"
        strokeWidth="0.5"
        fill="none"
        transform="rotate(-90) translate(-110 10)"
      />
      {percent > 0 && (
        <circle
          cx="50"
          cy="50"
          r="51"
          stroke="rgba(138, 197, 1, 1)"
          strokeWidth="7"
          fill="none"
          strokeDasharray={`${percentDasharray} 1000`}
          strokeDashoffset="0"
          strokeMiterlimit="0"
          transform="rotate(-90) translate(-110 10)"
        />
      )}
      <text
        fontWeight="300"
        fontFamily="IBM Plex Mono"
        fontSize="28px"
        x="50%"
        y="37%"
        dominantBaseline="middle"
        textAnchor="middle"
        fill="rgba(0, 0, 0, 1)"
      >
        {Math.round(props.value)}
      </text>
      <text
        fontWeight="normal"
        fontFamily="IBM Plex Mono"
        fontSize="12px"
        x="50%"
        y="55%"
        dominantBaseline="middle"
        textAnchor="middle"
        fill="rgba(0, 0, 0, 0.6)"
      >
        {valueString.split(' ')[0]}
      </text>
      <text
        fontWeight="normal"
        fontFamily="IBM Plex Mono"
        fontSize="12px"
        x="50%"
        y="67%"
        dominantBaseline="middle"
        textAnchor="middle"
        fill="rgba(0, 0, 0, 0.6)"
      >
        {valueString.split(' ')[1]} {valueString.split(' ')[2]}
      </text>
    </svg>
  );
};
