/* Radius of corners for all supported devices. We need these values to build device frame in interface
 */
export const cornerValues = {
  'iPhone10,3': 39,
  'iPhone10,6': 39,
  'iPhone11,2': 39,
  'iPhone11,4': 39,
  'iPhone11,6': 39,
  'iPhone11,8': 41.5,
  'iPhone12,1': 41.5,
  'iPhone12,3': 39,
  'iPhone12,5': 39,
  'iPhone13,1': 44,
  'iPhone13,2': 47.33,
  'iPhone13,3': 47.33,
  'iPhone13,4': 53.33,
  'iPad8,1': 18,
  'iPad8,2': 18,
  'iPad8,3': 18,
  'iPad8,4': 18,
  'iPad8,5': 18,
  'iPad8,6': 18,
  'iPad8,7': 18,
  'iPad8,8': 18,
  'iPad8,9': 18,
  'iPad8,10': 18,
  'iPad8,11': 18,
  'iPad8,12': 18,
  'iPad13,4': 18,
  'iPad13,5': 18,
  'iPad13,6': 18,
  'iPad13,7': 18,
  'iPad13,8': 18,
  'iPad13,9': 18,
  'iPad13,10': 18,
  'iPad13,11': 18
};
