import React from 'react';

import { HMTimeType, WindowType } from '../../types';

type EffectiveTimeProps = {
  hmTime: HMTimeType;
};

export const EffectiveTime = (props: EffectiveTimeProps) => {
  const wnd = (window as unknown) as WindowType;

  if (props.hmTime.hours === 0) {
    return (
      <>
        {props.hmTime.minutes}
        <span className="grey">{wnd.langDict.time.minute.short}</span>
        {/*<span className="grey only">*/}
        {/*</span>*/}
      </>
    );
  } else {
    return (
      <>
        {props.hmTime.hours}
        <span className="grey">{wnd.langDict.time.hour.short}</span>
        {props.hmTime.minutes > 0 && (
          <>
            &nbsp;
            {props.hmTime.minutes}
            <span className="grey">{wnd.langDict.time.minute.short}</span>
          </>
        )}
      </>
    );
  }
};
