import React from 'react';

import { Alert } from '../../../components/Alert';
import { getColorOnBaseOfConfidence } from '../../../helpers';
import { MetricInfoType, MetricType, WindowType } from '../../../types';

import './MetricMessage.scss';

type Props = {
  metric: MetricType;
  metricInfo: MetricInfoType;
};

const getMetricMessage = (metric: MetricType, metricInfo: MetricInfoType): string | undefined => {
  const wnd = (window as unknown) as WindowType;
  const { value, confidence } = metric;

  if (!confidence || confidence <= 0.01) {
    return wnd.langDict.web.reading_session.page_skipped;
  }

  const messages = Object.entries(metricInfo.metainfo.explanations);
  for (let i = messages.length - 1; i >= 0; i--) {
    const [limit, message] = messages[i];
    if (value >= Number(limit)) {
      return message[wnd.currentLang];
    }
  }
};

export const MetricMessage = (props: Props): JSX.Element | null => {
  const wnd = (window as unknown) as WindowType;
  const { metric, metricInfo } = props;

  if (!metric) {
    return null;
  }

  const metricValue = metricInfo.metainfo.invert ? 1 - metric.value : metric.value;

  const color = getColorOnBaseOfConfidence(metricInfo, metric, 1);
  const percents = Math.round(metricValue * 100);

  const message = getMetricMessage(metric, metricInfo);

  return (
    <div className="MetricMessage">
      {message ? <Alert message={message} color={color} grayscale={1 - (metric.confidence || 0)} /> : null}
      <div className="__explanation">
        <span>
          {wnd.langDict.analytics.percent_explanation
            .replace('%d', percents.toString())
            .replace('%@', metricInfo.metainfo.inline_name[wnd.currentLang].toLowerCase())}
        </span>
      </div>
    </div>
  );
};
