import React from 'react';

import { Grid } from '../../components/Grid';
import { SimpleCircleGraph } from '../../components/SimpleCircleGraph';
import { ReadingSpeedGraph } from '../../components/ReadingSpeedGraph';
import { ChapterType, UserType, WindowType } from '../../types';
import { getHMTimeFromSeconds, pluralFormat } from '../../helpers';
import { ATTENTION_METRIC } from '../../consts';

type Props = {
  chapter: ChapterType;
  user: UserType;
};

export const InProgress = (props: Props): JSX.Element => {
  const wnd = (window as unknown) as WindowType;
  const { chapter, user } = props;

  const readInPercents = Math.floor((chapter.words.read / chapter.words.total) * 100);

  const readingSpeed = chapter.reading_speed || user.reading_speed || 0;
  const readingSpeedPerMin = Math.round(readingSpeed * 60);

  const { hours: timeLeftHours, minutes: timeLeftMinutes } = getHMTimeFromSeconds(chapter.reading_prediction);

  const attentionMetric = chapter.metrics?.find(item => item.name === ATTENTION_METRIC);
  const effectiveReadingSpeedPerMin = attentionMetric ? Math.round(readingSpeed * attentionMetric.value * 60) : 0;

  return (
    <Grid className="InProgress">
      {/* top left */}
      <div className="card">
        <h3>{wnd.langDict.web.aggregated.words_read.title}</h3>
        <SimpleCircleGraph
          className="circle-graph --words-read"
          percents={readInPercents}
          color="#000000"
          bigLabel={chapter.words.read.toString()}
        />
        <div className="description grey">
          {wnd.langDict.web.aggregated.words_read.comparison_chapter.replace('%d', `${readInPercents}`)}
        </div>
      </div>

      {/* top right */}
      <div className="card">
        <h3>{wnd.langDict.web.aggregated.time_estimate.title.started}</h3>
        <div className="time">
          {timeLeftHours > 0 && (
            <div className="hours">
              {timeLeftHours}
              <span>{wnd.langDict.time.hour.short}</span>
            </div>
          )}
          {timeLeftMinutes > 0 && timeLeftHours > 0 && <span className="colon">:</span>}
          {(timeLeftMinutes > 0 || timeLeftHours === 0) && (
            <div className="minutes">
              {timeLeftMinutes}
              <span>{wnd.langDict.time.minute.short}</span>
            </div>
          )}
        </div>
        <span className="emoji" role="img" aria-label="wordsRead" aria-labelledby="wordsRead">
          ⏱
        </span>
        <div className="description grey">
          {wnd.langDict.web.aggregated.time_estimate.description.replace(
            '%@',
            `${readingSpeedPerMin} ${pluralFormat(
              effectiveReadingSpeedPerMin,
              [
                wnd.langDict.web.aggregated.reading_speed.unit.zero,
                wnd.langDict.web.aggregated.reading_speed.unit.one,
                wnd.langDict.web.aggregated.reading_speed.unit.other
              ],
              true
            )}`
          )}
        </div>
      </div>

      {/* bottom left */}
      <div className="card">
        <h3 className="mt-20">{wnd.langDict.web.aggregated.reading_speed.title}</h3>
        <div className="circle-graph --effective-speed">
          <ReadingSpeedGraph effectiveValue={effectiveReadingSpeedPerMin} value={readingSpeedPerMin} />
        </div>
        {/* !!attentionMetric?.value && (
          <>
            <div className="description">{wnd.langDict.web.aggregated.reading_speed.comparison}</div>
            <div className="value">
              <span className="green">{effectiveReadingSpeedPerMin}</span>
              <span className="small" style={wnd.currentLang === 'en' ? { width: '70px' } : { width: '46px' }}>
                {pluralFormat(
                  effectiveReadingSpeedPerMin,
                  [
                    wnd.langDict.web.aggregated.reading_speed.unit.zero,
                    wnd.langDict.web.aggregated.reading_speed.unit.one,
                    wnd.langDict.web.aggregated.reading_speed.unit.other
                  ],
                  true
                )}
              </span>
            </div>
          </>
        )*/}
      </div>

      <div className="card" />
    </Grid>
  );
};
