import React from 'react';
import cn from 'classnames';

import { getMetricInfo } from '../../helpers';
import { MetricInfoType, MetricType } from '../../types';
import { ATTENTION_METRIC, COMPREHENSION_METRIC } from '../../consts';
import { MetricIcon } from '../Icons';

type SmallPageProps = {
  pageMetrics: MetricType[];
  isCurrent?: boolean;
  pageNumber?: number | string;
  setCurrentPage: (page: string) => void;
  metricsInfo: MetricInfoType[];
  onPageClick: () => void;
};

export const SmallPage = (props: SmallPageProps) => {
  const attentionMetric = props.pageMetrics.find(metric => metric.name === ATTENTION_METRIC);
  const comprehensionMetric = props.pageMetrics.find(metric => metric.name === COMPREHENSION_METRIC);

  return (
    <div className={cn({ SmallPage: true, '--blue-border': props.isCurrent })} onClick={props.onPageClick}>
      <div className="__metrics">
        {attentionMetric && (
          <MetricIcon
            width={20}
            height={57}
            metric={attentionMetric}
            metricInfo={getMetricInfo(props.metricsInfo, attentionMetric.name)}
          />
        )}
        {comprehensionMetric && (
          <>
            <hr />
            <MetricIcon
              width={20}
              height={57}
              metric={comprehensionMetric}
              metricInfo={getMetricInfo(props.metricsInfo, comprehensionMetric.name)}
            />
          </>
        )}
      </div>
      <div className="__number">{props.pageNumber}</div>
    </div>
  );
};
