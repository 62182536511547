import React, { useCallback, useEffect, useState } from 'react';

import { PageType, SessionType } from '../../types';
import { NEXT, PREV } from '../../consts';
import arrowLeft from '../../assets/img/arrow_left.svg';
import arrowRight from '../../assets/img/arrow_right.svg';

import { Page } from './Page';

import './Book.scss';

type Props = {
  page: PageType;
  onArrowClick: (direction: string) => void;
  isFirst: boolean;
  isLast: boolean;
  session: SessionType;
};

export const Book = (props: Props) => {
  const [scale, setScale] = useState(1);
  const [height, setHeight] = useState(0);

  const { page, session } = props;

  const calculateStyles = useCallback(() => {
    const readingAreaWidth = Number(session.device.dimensions[page.metainfo.orientation].readingAreaWidth);
    const readingAreaHeight = Number(session.device.dimensions[page.metainfo.orientation].readingAreaHeight);

    const gap = Math.max(window.innerWidth - readingAreaWidth, (window.innerWidth / 450) * 180);
    let scaleTemp = (window.innerWidth - gap) / readingAreaWidth;

    if (scaleTemp > 1) {
      scaleTemp = 1;
    }

    setScale(scaleTemp);
    setHeight(readingAreaHeight);
  }, [session, page]);

  window.onresize = () => {
    calculateStyles();
  };

  useEffect(() => {
    calculateStyles();
  }, [calculateStyles]);

  return (
    <>
      <div className="Book" style={{ height: height * scale + 225 }}>
        <Page session={session} page={page} scale={scale} />

        {!props.isFirst && (
          <div className="arrow leftArrow" onClick={() => props.onArrowClick(PREV)}>
            <img src={arrowLeft} alt="back" />
          </div>
        )}
        {!props.isLast && (
          <div className="arrow rightArrow" onClick={() => props.onArrowClick(NEXT)}>
            <img src={arrowRight} alt="forward" />
          </div>
        )}
      </div>
    </>
  );
};
