import React, { useCallback, useEffect, useState } from 'react';

import { DocumentType, MetricInfoType, SessionType, WindowType } from '../../types';
import { API_HOST } from '../../consts';
import { jsonParse } from '../../helpers';

import { Metric } from './metric';
import { Details } from './details';

type Props = {
  setIsLoading: (value: boolean) => void;
  setInProcess: (value: boolean) => void;
  setError: (error: string) => void;
};

export const Session = (props: Props) => {
  const [session, setSessionObject] = useState<SessionType | null>(null);
  const [document, setDocument] = useState<DocumentType>();
  const [metricsInfo, setMetricsInfo] = useState<MetricInfoType[] | null>(null);

  const wnd = (window as unknown) as WindowType;
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get('sessionId');
  const pageName = urlParams.get('page') || '';

  const loadingData = useCallback(() => {
    fetch(`${API_HOST}/sessions/${sessionId}/`, {
      headers: { Authorization: `Bearer ${wnd.token}` }
    })
      .then(async response => {
        let errorMessage = null;

        if (response.status < 400) {
          const results = jsonParse<SessionType>(await response.text());

          if (results.is_failed) {
            errorMessage = wnd.langDict.web.loading_screen.processing_failed;
          }

          if (!errorMessage) {
            if (results.is_processed) {
              const [metricsInfo, document] = await Promise.all([
                fetch(`${API_HOST}/metrics/?is_active=true`, {
                  headers: { Authorization: `Bearer ${wnd.token}` }
                }).then(res => res.json()),
                fetch(`${API_HOST}/documents/${results.document_id}/`, {
                  headers: { Authorization: `Bearer ${wnd.token}` }
                }).then(res => res.json())
              ]);

              setSessionObject(results);
              if ('id' in document) {
                setDocument(document);
              }
              setMetricsInfo(metricsInfo);

              props.setInProcess(false);
            } else {
              props.setInProcess(true);
              setTimeout(() => {
                loadingData();
              }, 3000);
            }
          }
        } else {
          errorMessage = wnd.langDict.web.loading_screen.error + `${response.status}`;
        }

        if (errorMessage) {
          props.setInProcess(false);
          props.setError(errorMessage);
        }
      })
      .catch((err: Error) => {
        props.setInProcess(false);
        props.setError((wnd.langDict.web.loading_screen.error || 'Error happened: ') + `${err.message}`);
      })
      .finally(() => {
        props.setIsLoading(false);
      });
  }, [props, wnd, sessionId]);

  useEffect(() => {
    loadingData();
  }, [props, sessionId, wnd.token, loadingData]);

  return (
    <>
      {session && metricsInfo && (
        <>
          {pageName === 'session_metric' && (
            <Metric
              session={session}
              metricsInfo={metricsInfo}
              onLoadCallback={() => {
                props.setIsLoading(false);
              }}
            />
          )}
          {['session', ''].includes(pageName) && (
            <Details
              session={session}
              document={document}
              metricsInfo={metricsInfo}
              setIsLoading={(flag: boolean) => props.setIsLoading(flag)}
            />
          )}
        </>
      )}
    </>
  );
};
