import React from 'react';
import SegmentedControl from 'react-segmented-control-ios';

import { MetricInfoType, PagesType, SessionType, WindowType } from '../../types';
import { ALL, DEFECTIVE } from '../../consts';

import { SmallPage } from './SmallPage';

import './SmallPage.scss';

type Props = {
  currentPageId: string;
  pages: PagesType;
  setCurrentPage: (page: string) => void;
  hasFilters: boolean;
  setFiltering: (segment: string) => void;
  wrapperRef: React.RefObject<HTMLDivElement>;
  metricsInfo: MetricInfoType[];
  session: SessionType;
};

export const SmallPageList = (props: Props): JSX.Element | null => {
  const { pages, setFiltering } = props;
  const wnd = (window as unknown) as WindowType;

  const segments = [
    { name: wnd.langDict.web.reading_session.all_pages, value: ALL },
    { name: wnd.langDict.web.reading_session.low_attention_pages, value: DEFECTIVE }
  ];

  return (
    <>
      {props.hasFilters && (
        <SegmentedControl segments={segments} onChangeSegment={sId => setFiltering(segments[sId as number].value)} />
      )}
      <div ref={props.wrapperRef} className="SmallPageList">
        <div className="scrollContainer">
          {pages.map(page => (
            <SmallPage
              key={`metricGraphPage_${page.id}`}
              pageNumber={page.metainfo.page_no}
              onPageClick={() => props.setCurrentPage(page.id)}
              metricsInfo={props.metricsInfo}
              pageMetrics={page.metrics}
              isCurrent={props.currentPageId === page.id}
              setCurrentPage={props.setCurrentPage}
            />
          ))}
        </div>
      </div>
    </>
  );
};
