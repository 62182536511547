import React, { useState } from 'react';

import { WindowType } from '../../types';

import './CommentModal.scss';

type Props = {
  sendComment: (comment: string | null) => void;
  closeModal: () => void;
  text: string | null;
};

export const CommentModal = (props: Props) => {
  const wnd = (window as unknown) as WindowType;
  const [comment, setComment] = useState<string | null>(null);

  return (
    <div className="grey-bg">
      <div className="commentModal">
        <span className="closeIcon" onClick={props.closeModal}>
          X
        </span>
        <h1>{wnd.langDict.web.reading_session.report.title}</h1>
        <p>{wnd.langDict.web.reading_session.report.description}</p>
        <textarea
          onChange={event => setComment(event.target.value)}
          placeholder={wnd.langDict.web.reading_session.report.placeholder}
          defaultValue={props.text || ''}
        />
        <span className="mainButton" onClick={() => props.sendComment(comment || null)}>
          {wnd.langDict.web.reading_session.report.submit}
        </span>
      </div>
    </div>
  );
};
