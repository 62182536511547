import { WindowType } from '../../types';
import { pluralFormat } from '../../helpers';

export const pagesText = (value: number) => {
  const wnd = (window as unknown) as WindowType;
  return pluralFormat(value, [
    wnd.langDict.web.reading_session.page_count.zero,
    wnd.langDict.web.reading_session.page_count.one,
    wnd.langDict.web.reading_session.page_count.other
  ]);
};

const fullDateTimeValue = (value: number) => {
  const sValue = String(value);

  return sValue.length === 2 ? sValue : `0${sValue}`;
};

export const accurateTime = (value: number) => {
  const roundedValue = Math.round(value);

  const minutes = Math.floor(roundedValue / 60);
  const hours = Math.floor(minutes / 60);
  const secondsStr = fullDateTimeValue(roundedValue % 60);
  const minutesStr = fullDateTimeValue(minutes % 60);

  return `${hours > 0 ? `${hours}:` : ''}${minutesStr}:${secondsStr}`;
};

export const dateStringFromTimestamp = (ts: number) => {
  const date = new Date(ts * 1000);

  return `${fullDateTimeValue(date.getDay())}.${fullDateTimeValue(
    date.getMonth()
  )}.${date.getFullYear()} ${fullDateTimeValue(date.getHours())}:${fullDateTimeValue(date.getMinutes())}`;
};
