import React, { useState } from 'react';
import { LineChart, YAxis, XAxis, Line, CartesianGrid, ResponsiveContainer } from 'recharts';
import amplitude from 'amplitude-js';

import { CommentModal } from '../../../components/CommentModal/CommentModal';
import { MetricIcon } from '../../../components/Icons';
import { API_HOST } from '../../../consts';
import { getMetricInfo } from '../../../helpers';
import { MetricInfoType, SessionMetricType, SessionType, WindowType } from '../../../types';

import './Details.scss';

type Props = {
  metric: SessionMetricType;
  // setIsLoading: (isLoading: boolean) => void;
  metricsInfo: MetricInfoType[];
  session: SessionType;
};

const formatXAxis = (ts: number) => {
  const date = new Date(ts);

  return `${date.getHours()}:${date.getMinutes()}`;
};

const formatYAxis = (percents: number) => {
  return `${percents * 100}%`;
};

export const DetailsMetricGraph = (props: Props) => {
  const metricInfo = getMetricInfo(props.metricsInfo, props.metric.name);
  const wnd = (window as unknown) as WindowType;
  const urlParams = new URLSearchParams(window.location.search);

  const graphData = props.session.pages.map(page => {
    const pageMetric = page.metrics.find(m => m.name === props.metric.name);

    return {
      value: pageMetric ? pageMetric.value : 0,
      name: page.dt_start
    };
  });

  const [score, setScore] = useState<number | null>(props.metric.score);
  const [showModal, setShowModal] = useState<boolean>(false);

  const scoreMetric = (sessionId: string, token: string, metricName: string, score: number, comment: string | null) => {
    return async () => {
      const response = await fetch(`${API_HOST}/session/${sessionId}/metrics/${metricName}/`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          score,
          comment
        })
      });

      if (response.status < 400) {
        props.metric.score = score;
        props.metric.comment = comment;
        setScore(score);
      }
      setShowModal(false);

      if (comment) {
        amplitude.getInstance().logEvent('analytics comment: submitted', { sessionId, metric: metricName });
      }

      amplitude.getInstance().logEvent('analytics overview: metric rated', {
        sessionId,
        metric: metricName,
        button: score <= 3 ? 'negative' : 'positive'
      });
    };
  };

  return (
    <>
      <div className="DetailsMetricGraph">
        <h2 className="__header">
          <img src={`${process.env.PUBLIC_URL}/img/${metricInfo.metainfo.icon}`} />
          <span>{metricInfo.metainfo.display_name[wnd.currentLang]}</span>
        </h2>

        <div className="__graphs">
          <div className="__circle-with-thumbs">
            <MetricIcon width={106} height={156} metric={props.metric} metricInfo={metricInfo} />

            <div className="__thumbs">
              <span
                className={`up${score && score > 3 ? ' active' : ''}`}
                onClick={e => {
                  e.stopPropagation();
                  scoreMetric(
                    urlParams.get('sessionId') || '0',
                    urlParams.get('token') || '',
                    props.metric.name,
                    5,
                    null
                  )();
                }}
              />
              <span
                className={`down${score && score <= 3 ? ' active' : ''}`}
                onClick={e => {
                  e.stopPropagation();
                  setShowModal(true);
                }}
              />
            </div>
          </div>

          <ResponsiveContainer height={155} width="80%" className="__line-chart">
            <LineChart data={graphData}>
              <Line dataKey="value" type="monotone" strokeWidth={2} />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" axisLine={false} tickLine={false} tickFormatter={formatXAxis} height={15} />
              <YAxis
                dataKey="value"
                axisLine={false}
                tickLine={false}
                tickFormatter={formatYAxis}
                width={40}
                domain={[0, 1]}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      {showModal && (
        <CommentModal
          text={props.metric.comment}
          sendComment={(comment: string | null) => {
            scoreMetric(
              urlParams.get('sessionId') || '0',
              urlParams.get('token') || '',
              props.metric.name,
              1,
              comment
            )();
          }}
          closeModal={() => {
            setShowModal(false);
            amplitude.getInstance().logEvent('analytics comment: closed', {
              sessionId: urlParams.get('sessionId') || '0',
              metric: props.metric.name
            });
          }}
        />
      )}
    </>
  );
};
