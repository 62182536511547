import React from 'react';

import { getHMTimeFromSeconds } from '../../helpers';
import { WindowType } from '../../types';

type CircleTimeGraphProps = {
  effectiveTime: number;
  time: number;
};

const getDasharray = (percent: number) => {
  let dasharray = 3.37 * percent;

  if (percent >= 97) {
    dasharray -= 4;
  }

  return dasharray;
};

export const CircleTimeGraph = (props: CircleTimeGraphProps) => {
  const wnd = (window as unknown) as WindowType;

  const percent = Math.round((props.effectiveTime / props.time) * 100);
  const percentDasharray = getDasharray(percent);

  const hmTime = getHMTimeFromSeconds(props.time);

  return (
    <svg
      style={{ minWidth: '128px' }}
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      viewBox="0 0 120 120"
    >
      <circle
        cx="50"
        cy="50"
        r="55"
        stroke="rgba(0, 0, 0, 1)"
        strokeWidth="0.5"
        fill="none"
        transform="rotate(-90) translate(-110 10)"
      />
      {percent > 0 && (
        <circle
          cx="50"
          cy="50"
          r="53"
          stroke="rgba(0, 0, 0, 1)"
          strokeWidth="5"
          fill="none"
          strokeDasharray={`${percentDasharray} 1000`}
          strokeDashoffset="0"
          strokeMiterlimit="0"
          transform="rotate(-90) translate(-110 10)"
        />
      )}
      <text
        fontWeight="300"
        fontFamily="IBM Plex Mono"
        fontSize="28px"
        x="50%"
        y="47%"
        dominantBaseline="middle"
        textAnchor="middle"
        fill="rgba(0, 0, 0, 1)"
      >
        {hmTime.hours > 0
          ? `${hmTime.hours}:${hmTime.minutes < 10 ? `0${hmTime.minutes}` : hmTime.minutes}`
          : hmTime.minutes}
      </text>
      {hmTime.hours > 0 && (
        <text
          fontWeight="normal"
          fontFamily="IBM Plex Mono"
          fontSize="12px"
          x="29%"
          y="65%"
          dominantBaseline="middle"
          textAnchor="middle"
          fill="rgba(0, 0, 0, 0.6)"
        >
          {wnd.langDict.time.hour.short}
        </text>
      )}
      <text
        fontWeight="normal"
        fontFamily="IBM Plex Mono"
        fontSize="12px"
        x={hmTime.hours < 10 ? (hmTime.hours === 0 ? '50%' : '64%') : '71%'}
        y="65%"
        dominantBaseline="middle"
        textAnchor="middle"
        fill="rgba(0, 0, 0, 0.6)"
      >
        {wnd.langDict.time.minute.short}
      </text>
    </svg>
  );
};
