import React from 'react';

import { ChapterType, UserType, WindowType } from '../../types';
import { getHMTimeFromSeconds } from '../../helpers';

type Props = {
  chapter: ChapterType;
  user: UserType;
};

export const NotRead = (props: Props): JSX.Element => {
  const wnd = (window as unknown) as WindowType;
  const { chapter, user } = props;

  const userReadingSpeed = user.reading_speed ? user.reading_speed * 60 : 0;
  const roundedUserReadingSpeed = Math.round(userReadingSpeed);

  const { hours: timeLeftHours, minutes: timeLeftMinutes } = getHMTimeFromSeconds(chapter.reading_prediction);

  return (
    <div className="NotRead">
      <h3>{wnd.langDict.web.aggregated.time_estimate.title.not_started}</h3>
      <div className="time">
        {timeLeftHours > 0 && (
          <div className="hours">
            {timeLeftHours}
            <span>{wnd.langDict.time.hour.short}</span>
          </div>
        )}
        {timeLeftMinutes > 0 && timeLeftHours > 0 && <span className="colon">:</span>}
        {(timeLeftMinutes > 0 || timeLeftHours === 0) && (
          <div className="minutes">
            {timeLeftMinutes}
            <span>{wnd.langDict.time.minute.short}</span>
          </div>
        )}
      </div>
      <span className="emoji" role="img" aria-label="wordsRead" aria-labelledby="wordsRead">
        ⏱
      </span>
      <div className="description grey">
        {wnd.langDict.web.aggregated.time_estimate.description.replace(
          '%@',
          `${roundedUserReadingSpeed} ${
            roundedUserReadingSpeed > 1
              ? wnd.langDict.web.aggregated.reading_speed.unit.other
              : wnd.langDict.web.aggregated.reading_speed.unit.one
          }`
        )}
      </div>
    </div>
  );
};
