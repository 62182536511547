import React from 'react';

import { getColorOnBaseOfConfidence, getDasharray, getDasharrayInternal } from '../../helpers';
import { MetricInfoType, MetricType } from '../../types';

type PreviousCircleGraphProps = {
  isDark?: boolean;
  metricInfo: MetricInfoType;
  previousMetric: MetricType;
};

type CircleGraphProps = {
  isDark?: boolean;
  isBig?: boolean;
  metricInfo: MetricInfoType;
  metric: MetricType;
  previousMetric?: MetricType;
};

const PreviousCircleGraph = (props: PreviousCircleGraphProps) => {
  const previousPercent = Math.round(
    (props.metricInfo.metainfo.invert ? 1 - props.previousMetric.value : props.previousMetric.value) * 100
  );
  const previousPercentDasharray = getDasharrayInternal(previousPercent);

  return (
    <>
      {previousPercent > 0 && (
        <circle
          cx="50"
          cy="50"
          r="46"
          stroke={props.isDark ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 0.6)'}
          strokeWidth="3"
          fill="none"
          strokeDasharray={`${previousPercentDasharray} 1000`}
          strokeDashoffset="0"
          strokeMiterlimit="0"
          transform="rotate(-90) translate(-110 10)"
        />
      )}
      <text
        fontWeight="normal"
        fontFamily="IBM Plex Mono"
        fontSize="15px"
        x="50%"
        y="70%"
        dominantBaseline="middle"
        textAnchor="middle"
        fill={props.isDark ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 0.6)'}
      >
        {previousPercent}%
      </text>
    </>
  );
};

export const CircleGraph = (props: CircleGraphProps) => {
  const percent = Math.round((props.metricInfo.metainfo.invert ? 1 - props.metric.value : props.metric.value) * 100);
  const percentDasharray = getDasharray(percent);
  const color = getColorOnBaseOfConfidence(props.metricInfo, props.metric, 1);

  return (
    <svg
      style={{ minWidth: props.isBig ? '140px' : '128px' }}
      xmlns="http://www.w3.org/2000/svg"
      width={props.isBig ? '140px' : '128px'}
      height={props.isBig ? '140px' : '128px'}
      viewBox="0 0 120 120"
    >
      <circle
        cx="50"
        cy="50"
        r="55"
        stroke={props.isDark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.6)'}
        strokeWidth={props.isBig ? '1px' : '0.5px'}
        fill="none"
        transform="rotate(-90) translate(-110 10)"
      />
      {props.previousMetric !== undefined && (
        <>
          <PreviousCircleGraph
            previousMetric={props.previousMetric}
            metricInfo={props.metricInfo}
            isDark={props.isDark}
          />
        </>
      )}
      {percent > 0 && (
        <circle
          cx="50"
          cy="50"
          r="51"
          stroke={color}
          strokeWidth="7"
          fill="none"
          strokeDasharray={`${percentDasharray} 1000`}
          strokeDashoffset="0"
          strokeMiterlimit="0"
          transform="rotate(-90) translate(-110 10)"
        />
      )}
      <text
        fontWeight="normal"
        fontFamily="IBM Plex Mono"
        fontSize="25px"
        x="50%"
        y={props.previousMetric !== undefined ? '45%' : '50%'}
        dominantBaseline="middle"
        textAnchor="middle"
        fill={color}
      >
        {percent}%
      </text>
    </svg>
  );
};
