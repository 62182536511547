import React from 'react';
import QS from 'query-string';

import { AnalyticsDocumentType } from '../../types';

import { EventType } from './types';
import { BookCover } from './BookCover';

import './BookCoverList.scss';

type Props = {
  books: AnalyticsDocumentType[];
  header: string;
};

const openBookCallback = (bookId: number) => (e: EventType) => {
  e.stopPropagation();

  const qparams = QS.parse(window.location.search);

  const newLocationObj = {
    page: 'book_aggregated',
    from: 'aggregated',
    documentId: bookId,
    token: qparams.token,
    ...(qparams.userId && { userId: qparams.userId }),
    ...(qparams.amplitudeSessionId && { userId: qparams.amplitudeSessionId })
  };

  window.location.search = QS.stringify(newLocationObj);
};

export const BookCoverList = (props: Props) => {
  let token: string | null;
  const qParams = QS.parse(window.location.search);
  if (Array.isArray(qParams.token)) {
    token = qParams.token[0];
  } else {
    token = qParams.token;
  }

  const { books } = props;

  return (
    <div className="BookCoverList">
      <h2 className="__header">{props.header}</h2>
      <div className="__list-overflow">
        <div className="__list">
          {books.map(book => (
            <BookCover key={book.id} book={book} token={token} clickCallback={openBookCallback(book.id)} />
          ))}
        </div>
      </div>
    </div>
  );
};
