import React, { FC } from 'react';
import { nanoid } from 'nanoid';

export type IconProps = {
  width: number;
  height: number;
  borderColor: string;
  filledColor: string;
  filledHeight: number;
};

export const AttentionIcon: FC<IconProps> = ({ width, height, borderColor, filledColor, filledHeight }) => {
  const uuid = nanoid();

  return (
    <svg width={width} height={height} viewBox="0 0 266 366" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id={uuid} maskUnits="userSpaceOnUse" x="0" y={filledHeight} width="266" height="366">
        <path d="M266 39H0V405H266V39Z" fill={filledColor} />
      </mask>
      <g mask={`url(#${uuid})`}>
        <path
          d="M79.25 15.45L22.46 210.47C19.75 219.78 26.73 229.1
          36.44 229.1H91.14C96.06 229.1 99.88 233.41 99.27 238.3L86.77
          339.17C84.64 356.34 107.13 364.64 116.67 350.2L243.75 157.81C250.75
          147.22 243.15 133.09 230.45 133.09H183.8C178.68 133.09 175.16 127.95
          177.01 123.18L214.12 27.38C218.24 16.73 210.39 5.26001 198.97 5.26001H92.83C86.54
          5.26001 81.01 9.42001 79.25 15.45Z"
          fill={filledColor}
        />
      </g>
      <path
        d="M103.22 364.37C100.59 364.37 97.9 363.9 95.26 362.92C85.31 359.25
         79.5 349.71 80.81 339.19L93.31 238.31C93.42 237.46 93.02 236.87 92.78 236.59C92.54
         236.31 92 235.85 91.14 235.85H36.44C29.91 235.85 23.92 232.85 20 227.63C16.08 222.41
         14.88 215.82 16.71 209.55L73.5 14.53C75.99 5.99001 83.94 0.0200195 92.84
         0.0200195H198.97C206.31 0.0200195 213.18 3.64001 217.33 9.70001C221.48 15.76 222.37
         23.46 219.72 30.31L182.61 126.11C182.41 126.63 182.57 127.04 182.75 127.3C182.92 127.55
         183.25 127.86 183.81 127.86H230.46C238.54 127.86 245.95 132.28 249.78 139.4C253.61 146.52
         253.22 155.13 248.76 161.88L121.66 354.27C117.37 360.77 110.51 364.37 103.22 364.37ZM85.01
         17.89L28.22 212.9C27.46 215.51 27.96 218.25 29.59 220.43C31.22 222.6 33.71 223.85 36.43
         223.85H91.13C95.2 223.85 99.07 225.6 101.76 228.64C104.45 231.69 105.71 235.75 105.21
         239.78L92.71 340.66C91.97 346.67 95.68 350.28 99.41 351.66C103.14 353.04 108.31 352.7
         111.65 347.65L238.73 155.26C240.78 152.16 240.95 148.35 239.19 145.08C237.43 141.81 234.16
         139.85 230.44 139.85H183.79C179.41 139.85 175.31 137.69 172.83 134.07C170.35 130.45 169.82
         125.86 171.4 121.77L208.51 25.97C209.75 22.77 209.35 19.31 207.41 16.48C205.47 13.65 202.39
         12.02 198.95 12.02H92.83C89.23 12.02 86.01 14.43 85.01 17.89Z"
        fill={borderColor}
      />
    </svg>
  );
};
