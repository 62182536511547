import React from 'react';

import './Alert.scss';

type Props = {
  message: string;
  color: string;
  grayscale?: number;
};

export const Alert = (props: Props): JSX.Element => {
  const { grayscale, color, message } = props;

  return (
    <div className="Alert" style={{ backgroundColor: color, filter: `grayscale(${grayscale}` }}>
      {message}
    </div>
  );
};
