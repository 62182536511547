import React from 'react';

import { cornerValues } from '../../corners';
import { PageType, SessionType } from '../../types';
import { ATTENTION_METRIC } from '../../consts';

import './Page.scss';

type PageInnerProps = {
  page: PageType;
  isCurrent?: boolean;
  session: SessionType;
};

type PageProps = PageInnerProps & {
  scale: number;
};

export const Page = (props: PageProps): JSX.Element => {
  // const {
  //   pageInfo: { page, fragmentMetric, fragment }
  // } = props;
  const { page, session } = props;
  const attentionMetric = page.metrics.find(metric => metric.name === ATTENTION_METRIC);

  const dimensions = session.device.dimensions[page.metainfo.orientation];

  const width = Number(dimensions.readingAreaWidth);
  const height = Number(dimensions.readingAreaHeight);
  const padX = Number(dimensions.readingAreaOffset_x);
  const padY = Number(dimensions.readingAreaOffset_y);

  const textStyle = attentionMetric ? { filter: `grayscale(${1 - (attentionMetric.confidence || 0)})` } : {};

  const pageStyle = {
    width: `${width + 10}px`,
    height: `${height}px`,
    transform: `scale(${props.scale}, ${props.scale})`,
    fontFamily: `${page.metainfo.font || '-apple-system'}`,
    fontSize: `${page.metainfo.textSize}px`,
    lineHeight: 1.33,
    left: `calc(50% - ${((width + padX + 15) * props.scale) / 2}px)`,
    padding: `${padY}px ${padX - 5}px`,
    borderRadius: `${cornerValues[session.device.deviceModelName]}px`
  };

  return (
    <div className="Page" style={pageStyle}>
      {page.content && (
        <div className="paragraph">
          <div className="paragraphInner" style={textStyle} dangerouslySetInnerHTML={{ __html: page.content }} />
        </div>
      )}
    </div>
  );
};
