import React from 'react';

import { SimpleCircleGraph } from '../SimpleCircleGraph';
import { getColorOnBaseOfConfidence } from '../../helpers';
import { MetricInfoType, MetricType, WindowType } from '../../types';

type CircleComplexityGraphProps = {
  metricInfo: MetricInfoType;
  metric: MetricType;
  previousMetric?: MetricType;
};

const getLabel = (value: number, metainfo: MetricInfoType) => {
  const wnd = (window as unknown) as WindowType;

  if (value > 0.5) {
    return metainfo.metainfo.label_1 ? metainfo.metainfo.label_1[wnd.currentLang] : '';
  } else {
    return metainfo.metainfo.label_0 ? metainfo.metainfo.label_0[wnd.currentLang] : '';
  }
};

export const CircleComplexityGraph = (props: CircleComplexityGraphProps) => {
  const percents = props.metric.value * 10;
  const prevPercents = props.previousMetric?.value ? props.previousMetric.value * 10 : undefined;
  const color = getColorOnBaseOfConfidence(props.metricInfo, props.metric, 1);
  const bigLabel = props.metric.value.toFixed(2).toString();
  const label = getLabel(props.metric.value, props.metricInfo);

  return (
    <SimpleCircleGraph
      percents={percents}
      prevPercents={prevPercents}
      color={color}
      label={label}
      bigLabel={bigLabel}
    />
  );
};
