import React from 'react';

import greenArrow from '../../assets/img/stats-green-arrow.svg';
import blackArrow from '../../assets/img/stats-black-arrow.svg';

import './Analytics.scss';

type Props = {
  value: number;
  previousValue: number;
};

export const PercentLine = (props: Props) => {
  if (props.previousValue === 0) {
    if (props.value === 0) {
      return <div className="percent">0%</div>;
    } else {
      return (
        <div className="percent">
          <img src={greenArrow} alt="green arrow" />
          +100%
        </div>
      );
    }
  } else {
    const diff = Math.abs(props.previousValue - props.value);
    let prefix = '';

    if (props.previousValue > props.value) {
      prefix = '-';
    } else if (props.previousValue < props.value) {
      prefix = '+';
    }

    return (
      <div className="percent">
        {prefix === '+' && <img src={greenArrow} alt="green arrow" />}
        {prefix === '-' && <img src={blackArrow} alt="black arrow" />}
        {prefix}
        {Math.round((diff / props.previousValue) * 100)}%
      </div>
    );
  }
};
